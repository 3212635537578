import { useEffect, useState } from "react";

export function useWindowSize() {
    // Initialize state with default values
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    // Define your breakpoints for width and height
    const BREAKPOINTS = {
        xs: { width: 0, height: 0 },      // Extra Small
        sm: { width: 600, height: 400 },  // Small
        md: { width: 960, height: 600 },  // Medium
        lg: { width: 1280, height: 800 }, // Large
        xl: { width: 1920, height: 1080 } // Extra Large
    };

    // Boolean values based on window width and height
    const isXs = windowSize.width <= BREAKPOINTS.sm.width && windowSize.height <= BREAKPOINTS.sm.height;
    const isSm = windowSize.width > BREAKPOINTS.sm.width && windowSize.width <= BREAKPOINTS.md.width &&
                 windowSize.height > BREAKPOINTS.sm.height && windowSize.height <= BREAKPOINTS.md.height;
    const isMd = windowSize.width > BREAKPOINTS.md.width && windowSize.width <= BREAKPOINTS.lg.width &&
                 windowSize.height > BREAKPOINTS.md.height && windowSize.height <= BREAKPOINTS.lg.height;
    const isLg = windowSize.width > BREAKPOINTS.lg.width && windowSize.width < BREAKPOINTS.xl.width &&
                 windowSize.height > BREAKPOINTS.lg.height && windowSize.height <= BREAKPOINTS.xl.height;
    const isXl = windowSize.width >= BREAKPOINTS.xl.width || windowSize.height >= BREAKPOINTS.xl.height;

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Cleanup event listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return { isXs, isSm, isMd, isLg, isXl, windowSize };
}
