import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import ResumeScoreProgressBar from "./ResumeScoreProgressBar";
import { useState } from "react";
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DoneIcon from '@mui/icons-material/Done';

const NameScoreWrapper = () => {
    const [name,setName] = useState<string>("Entry Level Resume");
    const [edit,setEdit] = useState<boolean>(false);

  return (
    <Box className="name-score-wrapper-container">
        <Box className="name-container">
            {!edit ? <Box className="name-wrapper">
             <Typography sx={{fontSize:"20px", fontWeight:"600"}}>{name}</Typography>
             <IconButton onClick={()=>setEdit(true)}>
             <ModeEditOutlinedIcon/>
             </IconButton>
            </Box> 
            :<Box className="edit-wrapper">
                <TextField onChange={(e)=> setName(e.target.value)} value={name} size="small" />
                    <IconButton disabled={!name}><DoneIcon onClick={()=>setEdit(false)}/></IconButton>
            </Box>}
        </Box>
        <Box className="progress-container">
            <ResumeScoreProgressBar/>
        </Box>
    </Box>
  )
}
export default NameScoreWrapper;