import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {ReactComponent as SelectFontIcon} from "../assets/SVG/tabler_text-size.svg";
import {ReactComponent as ChosseTemplatetIcon} from "../assets/SVG/templates-icon.svg";
import {ReactComponent as DoewnloadIcon} from "../assets/SVG/download-icon.svg";
import {ReactComponent as FontPlusIcon} from "../assets/SVG/plus.svg";
import {ReactComponent as FontMinusIcon} from "../assets/SVG/minus.svg";
import Button from '@mui/material/Button'
import ColorPicker from "./ColorPicker";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useSelector } from "react-redux";
// import { TemplateHandler } from 'easy-template-x';
// import { saveAs } from 'file-saver';
import { useState } from "react";
import { useDispatch } from 'react-redux';
import { setExtractedData } from "../redux/actions";
import { getToken } from "../components/auth/storeToken";
import { flattenExtractedData } from "../components/utils";

const ProcessResumePreviewHeader = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const extractedData = useSelector((state:any) => state.extractedData);
    const selectedTemplatedId = useSelector((state: any) => state.templateId);
    const [error, setError] = useState('');

    const generateDocument = async (extractedData: any) => {
        try {
              const payload = {
                data: {
                    ...flattenExtractedData(extractedData),
                },
              };
              
              const serverResponse = await fetch('https://be.tekno.ai/process-word', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
              });
      
              if (!serverResponse.ok) {
                throw new Error('Failed to process the document');
              }
              
              // Create a blob from the response
              const processedBlob = await serverResponse.blob();
              const url = window.URL.createObjectURL(processedBlob);
      
              // Create a hidden link element and trigger the download
              const link = document.createElement('a');
              link.href = url;
              link.download = 'processed.docx';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
          } catch (error:any) {
            setError(error.message);
          }
    };    

    const handleDownload = () => {
        generateDocument(extractedData);
    }

    const saveResume = async () => {
      const now = new Date();
      const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: 'numeric' };
      const formattedDate = now.toLocaleDateString('en-US', options).replace(',', '');

      const resume_name = `Resume - ${formattedDate}`;
      const updatedData = { ...extractedData, resume_name, template_id: selectedTemplatedId, created_at: Date.now() };

      dispatch(setExtractedData(updatedData));
      
      // Prepare the data for the POST request
      const requestData = {
          resumeJson: JSON.stringify(updatedData)
      };

      try {
          const response = await fetch('https://testapi.tekno.ai/api/add-resume', {
              method: 'POST',
              headers: {
                  'accept': '*/*',
                  'Authorization': 'Bearer ' + getToken(),
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(requestData)
          });

          if (response.ok) {
              console.log('Resume saved successfully');
          } else {
              console.error('Failed to save resume');
          }
      } catch (error) {
          console.error('Error saving resume:', error);
      }

    }

    return (
        <Box className="content">
            <Box className="left">
                <Box className="icons-container">
                    <Box className="icon-box">
                        <IconButton>
                        <FontPlusIcon/>
                        </IconButton>
                        <SelectFontIcon/>
                        <IconButton>
                        <FontMinusIcon/>
                        </IconButton>

                    </Box>
                    <Box className="icon-box">
                        <div className="div-as-color-picker-icon"><div className="template-color"></div>
                        <Box className="picker-box">
                        <ColorPicker/>
                        </Box>
                        </div>
                    </Box>
                </Box>
            </Box>
            <Box className="middle">
             <Box>
              <Typography variant="body1" sx={{color:"#fff"}}>1 of 1 Pages</Typography>
             </Box> 
            </Box>
            <Box className="right">
                <Box className="button-controls">
                    <Button variant="outlined" startIcon={<ChosseTemplatetIcon/>}
                     size="small" className="tekno-black-outline-button"
                     onClick={()=> navigate("/select-template")}
                     sx={{backgroundColor: "white"}}
                     >
                      Templates
                    </Button>
                     <Button variant="outlined" size="medium" className="tekno-black-outline-button" onClick={() => saveResume()} sx={{backgroundColor: "white"}}>
                      Preview
                    </Button>
                    <Button variant="contained" size="medium" startIcon={<DoewnloadIcon/>} onClick={() => handleDownload()}>
                      Download
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
export default ProcessResumePreviewHeader;