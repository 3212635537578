import { Autocomplete, Box, Button, Chip, createFilterOptions, Modal, TextField, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { setExtractedData } from "../../redux/actions";
import ReactQuill, { displayName } from "react-quill";
import { quillModules } from "./WorkExperience";
import { useEffect, useRef, useState } from "react";
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { hobbiesData, languagesData, skillOption } from "../../Data/skillsData";
import {ReactComponent as CourseIcon} from "../../assets/SVG/courses-icon.svg";
import {ReactComponent as ProjectIcon} from "../../assets/SVG/project-icon.svg";
import {ReactComponent as ReferencessIcon} from "../../assets/SVG/preferencess-icon.svg";
import {ReactComponent as LanguagesIcon} from "../../assets/SVG/languages-icon.svg";
import {ReactComponent as AwardsIcon} from "../../assets/SVG/award-icon.svg";
import {ReactComponent as HobbiesIcon} from "../../assets/SVG/hobbies-icon.svg";
import {ReactComponent as VoulnteerIcon} from "../../assets/SVG/volunteer-work-icon.svg";
import {ReactComponent as ExtarCurricularActivitiesIcon} from "../../assets/SVG/ExtraCurricularActivities-icon.svg";
import {ReactComponent as CustomSection} from "../../assets/SVG/custom-icon.svg";
import {ReactComponent as AddSectionIcon} from "../../assets/SVG/add-section-icon.svg";
import {ReactComponent as RemoveSectionIcon} from "../../assets/SVG/remove-section-new.svg";
import {ReactComponent as CancelModalIcon} from "../../assets/SVG/cancel-modal.svg";
import Languages from "./SummarySections/Languages";
import Hobbies from "./SummarySections/Hobbies";
import CoursesList from "./SummarySections/Courses/CoursesList";
import ProjectsList from "./SummarySections/Projects/ProjectsList";
import AwardsList from "./SummarySections/Awards/AwardsList";
import ReferencesList from "./SummarySections/Referencess/ReferencesList";
import VolunteerWorksList from "./SummarySections/VolunteerWork/VolunterrWorksList";
import ExtraCAList from "./SummarySections/extraCA/ExtraCAList";
import CustomSectionList from "./SummarySections/CustomSection/CustomSectionList";
import { AddTask } from "@mui/icons-material";
import ImproveResumeBtn from "../ImproveResumeBtn";
import GetHelpWithWriting from "../GetHelpWithWriting";

export const summarSectionModalstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius:"15px",
    boxShadow: 0,
  };

const Summary = () => {
    const dispatch = useDispatch();
    const extractedData = useSelector((state:any) => state.extractedData);
    const [removeableSections,setRemoveableSections] = useState<string[]>([]);
    const careerObjectiveInputRef = useRef(null);
    const [otherSectionList,setOtherSectionList] = useState([
        {
            label:"Courses",
            value:"courses",
            icon:<CourseIcon/>,
            visible:true,
        },
        {
            label:"Projects",
            value:"projects",
            icon:<ProjectIcon/>,
            visible:true,
        },
        {
            label:"References",
            value:"references",
            icon:<ReferencessIcon/>,
            visible:true,
        },
        {
            label:"Languages",
            value:"languages",
            icon:<LanguagesIcon/>,
            visible:true,
        },
        {
            label:"Awards",
            value:"awards",
            icon:<AwardsIcon/>,
            visible:true,
        },
        {
            label:"Hobbies",
            value:"hobbies",
            icon:<HobbiesIcon/>,
            visible:true,
        },
        {
            label:"Volunteer Work",
            value:"volunteerWorks",
            icon:<VoulnteerIcon/>,
            visible:true,
        },
        {
            label:"Extracurricular Activities",
            value:"extraCA",
            icon:<ExtarCurricularActivitiesIcon/>,
            visible:true,
        },
        {
            label:"Custom section",
            value:"customSection",
            icon:<CustomSection/>,
            visible:true,
        },
    ]);
    const [summarySectionModal,setSummarySectionModal] = useState({modalState:false,sectionName:"",sectionDelete:false});

    //to delete section 
    useEffect(()=>{
        if(summarySectionModal?.modalState && summarySectionModal?.sectionDelete) {
            setRemoveableSections((prev)=> prev.filter(sec => sec!==summarySectionModal?.sectionName))
            const updatedOtherSection = otherSectionList?.map((ots)=>{
                if(ots.value === summarySectionModal?.sectionName) {
                    return {
                        ...ots,
                        visible:true
                    }
                }
                return ots;
            })
            setOtherSectionList(updatedOtherSection);

            dispatch(setExtractedData({
                        ...extractedData,
                        [summarySectionModal?.sectionName]:[],
                        }));
        }
       setSummarySectionModal({modalState:false,sectionName:"",sectionDelete:false})
    },[summarySectionModal?.sectionDelete]);

    const handleChange = (newValue: any, key: string) => {
        dispatch(setExtractedData({ ...extractedData, [key]: newValue }));
      };

    const handleAddOtherSectionAction = (sectionName:string) => {
        setRemoveableSections([...removeableSections, sectionName]);
        const updatedOtherSection = otherSectionList?.map((ots)=>{
            if(ots.value === sectionName) {
                return {
                    ...ots,
                    visible:false
                }
            }
            return ots;
        })
        setOtherSectionList(updatedOtherSection);
    }

    const handleSectionDelete = (section:string) => {
        setSummarySectionModal((prev)=> ({...prev,modalState:true,sectionName:section}));
    }

    return (
        <Box className="summary-container">
            <Box className="professional-summary-section">
                <Box className="heading-box" style={{ justifyContent: "flex-start", alignItems: "center" }}>
                    <Typography variant="h5" color="color.primary" className="heading">
                        Professional Summary
                    </Typography>
                    <ImproveResumeBtn section={"careerObjective"} value={extractedData.careerObjective}/>
                    <GetHelpWithWriting section={"careerObjective"} value={extractedData.careerObjective} inputRef={careerObjectiveInputRef}/>
                </Box>

                <Box className="professional-summary">
                <Box className="quill-container">
                      <ReactQuill
                        ref={careerObjectiveInputRef}
                        placeholder="Description"
                        value={extractedData?.careerObjective}
                        onChange={(e) => handleChange(e, 'careerObjective')}
                        theme="snow" 
                        modules={quillModules}
                        />
                    </Box>
              </Box>     
            </Box>

            {removeableSections.map(section => {
                if(section === "languages") {
                    return (
                        <Languages handleSectionDelete={handleSectionDelete} sectionName={section}/>
                    )
                }
                else if(section === "hobbies") {
                    return (
                        <Hobbies  handleSectionDelete={handleSectionDelete} sectionName={section}/>
                    )
                }
                else if(section === "courses") {
                    return (
                        <CoursesList handleSectionDelete={handleSectionDelete} sectionName={section}/>
                    )
                }
                else if(section === "projects") {
                    return (
                        <ProjectsList handleSectionDelete={handleSectionDelete} sectionName={section}/>
                    )
                }
                else if(section === "awards") {
                    return (
                        <AwardsList handleSectionDelete={handleSectionDelete} sectionName={section}/>
                    )
                }
                else if(section === "references") {
                    return (
                        <ReferencesList handleSectionDelete={handleSectionDelete} sectionName={section}/>
                    )
                }
                else if(section === "volunteerWorks") {
                    return (
                        <VolunteerWorksList handleSectionDelete={handleSectionDelete} sectionName={section}/>
                    )
                }
                else if(section === "extraCA") {
                    return (
                        <ExtraCAList handleSectionDelete={handleSectionDelete} sectionName={section}/>
                    )
                }
                else if(section === "customSection") {
                    return (
                        <CustomSectionList handleSectionDelete={handleSectionDelete} sectionName={section}/>
                    )
                }

            })}

            <Box className="other-section-container">
                <Box className="heading-box">
                    <Typography variant="h5" color="color.primary" className="heading">
                     Other
                    </Typography>
                </Box>

                <Box className="other-section-content">
                    {otherSectionList?.map((ots)=> {
                        return ots.visible ? 
                        <Box className="chip-wrapper">
                        <Box  className="icon-container">
                            {ots.icon}
                        </Box>
                        {
                        // ots?.value === "customSection" ?  <Box  className="text-cont">
                        //     <Typography className="other-name">{ots.label}{" "} <EditIcon/></Typography>
                        // </Box> 
                        // :
                         <Box  className="text-cont">
                            <Typography className="other-name">{ots.label}</Typography>

                        </Box>}
                        <Box  className="action-cont" onClick={()=>handleAddOtherSectionAction(ots.value)}><AddSectionIcon/></Box>
                    </Box> 
                    :null;
                    }
                    )}
                </Box>

            </Box>

        <Modal
        keepMounted
        open={summarySectionModal?.modalState}
        // onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={summarSectionModalstyle}>
        <Box sx={{    display:"flex",
                    flexDirection:"column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    pt:3,
                    pl:3,
                    pr:3,
                    pb:3,
                    }}>
            <Box
            sx={{width:"100%",display:"flex",justifyContent: "left",alignItems: "center",gap:"1rem",marginBottom:"0.5rem"}}
            >
            <RemoveSectionIcon/>
            <Typography sx={{flex:"1",fontSize:"24px",fontWeight:"600",fontFamily:"inter"}} id="keep-mounted-modal-title" variant="h6" component="h2">
            Remove Section?
           </Typography>
           <Box>
            
            <Box sx={{cursor:"pointer"}} onClick={()=> setSummarySectionModal((prev)=> ({...prev,modalState:false,sectionDelete:false}))}>
            <CancelModalIcon/>
            </Box>
           </Box>
           </Box>
 
          <Typography sx={{fontSize:"18px"}} id="keep-mounted-modal-description">
            Please confirm, this can't be restore.
          </Typography>
          <Typography sx={{fontSize:"18px",fontWeight:"500",fontFamily:"inter",padding:"0.5rem 0"}}>"{summarySectionModal?.sectionName}"</Typography>
        </Box>
        <Box sx={{width:"100%",display:"flex",justifyContent:"right",gap:"1rem",
                    pr:3,
                    pb:3,}}>
            <Button onClick={()=> setSummarySectionModal((prev)=> ({...prev,sectionDelete:true}))} sx={{border:"1px solid #cbd5e1",color:"#182230"}}>Delete</Button>
            <Button variant="contained" onClick={()=> setSummarySectionModal((prev)=> ({...prev,sectionDelete:false}))}>Cancel</Button>
        </Box>
        </Box>

      </Modal>
        </Box>
    )
}

export default Summary;