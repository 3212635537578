// Template3.tsx
import React from 'react';
import styles from './Template3.module.css';
import { useSelector } from 'react-redux';

interface ResumePreviewProps {
  resumeDetails: any;
  wrapperRef: any;
  selectedScreen: any;
}

const Template3 = (props: any) => {
  const resumeDetails = useSelector((state:any) => state.extractedData);

  return (
    <div className={styles.resume}>
      <div className={styles["resume-header"]}>
        <img src={resumeDetails?.profile_Picture ? resumeDetails?.profile_Picture : "./profile-picture.jpg"} alt="Profile" className={styles["profile-picture"]} />
        <h1 className={styles["header-name"]}>{resumeDetails?.name?.firstName} {resumeDetails?.name?.lastName}</h1>
        <h2 className={styles["header-title"]}>{resumeDetails?.designation}</h2>
      </div>

      <div className={styles["resume-section"]}>
        <h3>Contact Information</h3>
        <p>Email: {resumeDetails?.email}</p>
        <p>Phone: {resumeDetails?.phone}</p>
      </div>

      <div className={styles["resume-section"]}>
        <h3>Summary</h3>
        <p>{resumeDetails?.careerObjective}</p>
      </div>

      <div className={styles["resume-section"]}>
        <h3>Experience</h3>
        {resumeDetails?.workExperiences && resumeDetails?.workExperiences.map((workExperience: any, index: number) => (
          <div key={index}>
            <h4>{workExperience.role} at {workExperience.company}</h4>
            <p>{workExperience.startDate} - {workExperience.endDate}</p>
            <ul>
              {workExperience.keyResponsibilities.split('\n').map((resp: string, i: number) => (
                <li key={i}>{resp}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div className={styles["resume-section"]}>
        <h3>Education</h3>
        {resumeDetails?.educationalDetails && resumeDetails?.educationalDetails.map((education: any, index: number) => (
          <div key={index}>
            <h4>{education.degree} at {education["university/College"]}</h4>
            <p>{education.startDate} - {education.endDate}</p>
          </div>
        ))}
      </div>

      <div className={styles["resume-section"]}>
        <h3>Skills</h3>
        {resumeDetails?.toolsTechnicalSkills && typeof resumeDetails?.toolsTechnicalSkills === "string" ? resumeDetails?.toolsTechnicalSkills : resumeDetails?.toolsTechnicalSkills?.map((skill: string, index: number) => (
          <p key={index}>{skill}</p>
        ))}
      </div>
    </div>
  )
};

export default Template3;