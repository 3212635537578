import { Box, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

const ProcessResumeFooter = () => {
    const navigate = useNavigate();
    const url = useParams();
    const params = url["*"];
    let footerObj:{[key:string]:{prev:string|null,next:string|null}} = {};
    if (params) {
         footerObj = {
            "personal-details":{
                prev:null,
                next:"work-experience"
            },
            "work-experience" :{
                prev:"personal-details",
                next:"education",
            },
            "education":{
                prev:"work-experience",
                next:"skills",
            },
            "skills":{
                prev:"/process-resume/education",
                next:"/process-resume/summary",
            },
            "summary":{
              prev:"/process-resume/education",
              next:null,
          }

        }
    }
    return (
        
          <Box className="footer">
            <Box className="footer-cancel">
            <Button variant="outlined" size="medium"  className="tekno-grey-outline-black-button"
             sx={{border:"1px solid black !important"}}
             onClick={()=> navigate("/")}
             >
                  Cancel
            </Button>
          </Box>
          {params && footerObj[params] && <Box className="footer-wrapper">
              <Button variant="outlined" size="medium" 
              className="tekno-grey-outline-black-button"
              onClick={()=> {
                if(footerObj[params]?.prev && typeof footerObj[params]?.prev === "string") {
                    //@ts-ignore
                  return  navigate(footerObj[params]?.prev)
                }
                }
              }
              >
                Previous
              </Button>
                <Button variant="contained" size="large" color="primary"
                  onClick={()=> {
                      if(footerObj[params]?.next && typeof footerObj[params]?.next === "string") {
                          //@ts-ignore
                        return  navigate(footerObj[params]?.next)
                      }
                      }}
                >
                  Next
                </Button>
            </Box>}
          </Box>
        
    )
}
export default ProcessResumeFooter;