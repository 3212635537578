export const GoogleIcon = () => (
  <svg width="20" height="19" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1081_468)">
      <path d="M24.5 12.3808C24.5 11.5843 24.4311 10.7836 24.2842 10H12.5V14.5119H19.2483C18.9682 15.9671 18.0685 17.2544 16.751 18.0724V21H20.777C23.1412 18.9593 24.5 15.9456 24.5 12.3808Z" fill="#4285F4" />
      <path d="M12.4084 24C15.7007 24 18.4771 22.9045 20.5 21.0136L16.5663 17.9227C15.4719 18.6773 14.059 19.1045 12.4129 19.1045C9.22828 19.1045 6.52809 16.9273 5.55925 14H1.5V17.1864C3.57224 21.3636 7.79297 24 12.4084 24Z" fill="#34A853" />
      <path d="M5.5 14.1318C5.02241 12.7488 5.02241 11.2512 5.5 9.86825V7H1.71282C0.0957268 10.1465 0.0957268 13.8535 1.71282 17L5.5 14.1318Z" fill="#FBBC04" />
      <path d="M12.3648 4.89366C14.0982 4.8664 15.7735 5.52968 17.0288 6.7472L20.5 3.21729C18.302 1.11841 15.3848 -0.0355109 12.3648 0.000833182C7.76781 0.000833182 3.56395 2.63578 1.5 6.81535L5.53856 10C6.49906 7.06976 9.19292 4.89366 12.3648 4.89366Z" fill="#EA4335" />
    </g>
    <defs>
      <clipPath id="clip0_1081_468">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export const LinkedInIcon = () => (
  <svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3333 2.5H4.66667C3.74583 2.5 3 3.24583 3 4.16667V15.8333C3 16.7542 3.74583 17.5 4.66667 17.5H16.3333C17.2542 17.5 18 16.7542 18 15.8333V4.16667C18 3.24583 17.2542 2.5 16.3333 2.5ZM7.53542 15.4167H5.29792V8.19375H7.53542V15.4167ZM6.40625 7.24792C5.675 7.24792 5.08333 6.65208 5.08333 5.91458C5.08333 5.17708 5.675 4.58125 6.40625 4.58125C7.1375 4.58125 7.72917 5.17708 7.72917 5.91458C7.72917 6.65208 7.1375 7.24792 6.40625 7.24792ZM15.9167 15.4167H13.6917V11.625C13.6917 10.5854 13.2958 10.0042 12.475 10.0042C11.5792 10.0042 11.1125 10.6083 11.1125 11.625V15.4167H8.96667V8.19375H11.1125V9.16667C11.1125 9.16667 11.7583 7.97292 13.2896 7.97292C14.8208 7.97292 15.9187 8.90833 15.9187 10.8437V15.4167H15.9167Z"
      fill="#0077B5"
    />
  </svg>
);

export const BulbIcon = () => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 16.334C17.7333 15.1673 18.3167 14.3507 19.25 13.4173C20.4167 12.3673 21 10.8507 21 9.33398C21 7.47747 20.2625 5.69699 18.9497 4.38424C17.637 3.07148 15.8565 2.33398 14 2.33398C12.1435 2.33398 10.363 3.07148 9.05025 4.38424C7.7375 5.69699 7 7.47747 7 9.33398C7 10.5007 7.23333 11.9007 8.75 13.4173C9.56667 14.234 10.2667 15.1673 10.5 16.334M10.5 21.0007H17.5M11.6667 25.6673H16.3333"
      stroke="#F59E0B"
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EmailIcon = () => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5 4C16.8807 4 18 5.11929 18 6.5V14.5C18 15.8807 16.8807 17 15.5 17H4.5C3.11929 17 2 15.8807 2 14.5V6.5C2 5.11929 3.11929 4 4.5 4H15.5ZM17 7.961L10.2535 11.931C10.1231 12.0077 9.96661 12.0205 9.82751 11.9693L9.74649 11.931L3 7.963V14.5C3 15.3284 3.67157 16 4.5 16H15.5C16.3284 16 17 15.3284 17 14.5V7.961ZM15.5 5H4.5C3.67157 5 3 5.67157 3 6.5V6.802L10 10.9199L17 6.801V6.5C17 5.67157 16.3284 5 15.5 5Z"
      fill="#525866"
    />
  </svg>
);

export const LockIcon = () => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 2C11.6569 2 13 3.34315 13 5V6H14C15.1046 6 16 6.89543 16 8V15C16 16.1046 15.1046 17 14 17H6C4.89543 17 4 16.1046 4 15V8C4 6.89543 4.89543 6 6 6H7V5C7 3.34315 8.34315 2 10 2ZM14 7H6C5.44772 7 5 7.44772 5 8V15C5 15.5523 5.44772 16 6 16H14C14.5523 16 15 15.5523 15 15V8C15 7.44772 14.5523 7 14 7ZM10 10.5C10.5523 10.5 11 10.9477 11 11.5C11 12.0523 10.5523 12.5 10 12.5C9.44772 12.5 9 12.0523 9 11.5C9 10.9477 9.44772 10.5 10 10.5ZM10 3C8.89543 3 8 3.89543 8 5V6H12V5C12 3.89543 11.1046 3 10 3Z"
      fill="#525866"
    />
  </svg>
);

export const VisibilityOn = () => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.25909 11.6021C3.94254 8.32689 6.79437 6 10 6C13.2057 6 16.0574 8.32688 16.7409 11.6021C16.7974 11.8725 17.0622 12.0459 17.3325 11.9895C17.6029 11.933 17.7763 11.6682 17.7199 11.3979C16.9425 7.67312 13.6934 5 10 5C6.3066 5 3.05742 7.67311 2.28017 11.3979C2.22377 11.6682 2.39718 11.933 2.6675 11.9895C2.93782 12.0459 3.20268 11.8725 3.25909 11.6021ZM10 8C8.067 8 6.5 9.567 6.5 11.5C6.5 13.433 8.067 15 10 15C11.933 15 13.5 13.433 13.5 11.5C13.5 9.567 11.933 8 10 8ZM7.5 11.5C7.5 10.1193 8.61929 9 10 9C11.3807 9 12.5 10.1193 12.5 11.5C12.5 12.8807 11.3807 14 10 14C8.61929 14 7.5 12.8807 7.5 11.5Z"
      fill="#212121"
    />
  </svg>
);

export const ProfileIcon = () => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.19077 2.78283C7.93586 2.06806 8.94643 1.6665 10.0001 1.6665C11.0539 1.6665 12.0644 2.06806 12.8095 2.78283C13.5546 3.4976 13.9732 4.46703 13.9732 5.47787C13.9732 6.48871 13.5546 7.45815 12.8095 8.17292C12.0644 8.88769 11.0539 9.28924 10.0001 9.28924C8.94643 9.28924 7.93586 8.88769 7.19077 8.17292C6.44568 7.45815 6.02709 6.48871 6.02709 5.47787C6.02709 4.46704 6.44568 3.4976 7.19077 2.78283ZM10.0001 2.82929C9.2679 2.82929 8.56564 3.10834 8.04787 3.60505C7.53009 4.10175 7.23921 4.77543 7.23921 5.47787C7.23921 6.18032 7.53009 6.854 8.04787 7.3507C8.56564 7.84741 9.2679 8.12645 10.0001 8.12645C10.7324 8.12645 11.4346 7.84741 11.9524 7.3507C12.4702 6.854 12.7611 6.18032 12.7611 5.47787C12.7611 4.77543 12.4702 4.10175 11.9524 3.60505C11.4346 3.10834 10.7324 2.82929 10.0001 2.82929ZM9.69929 10.711C10.594 10.674 11.4873 10.8099 12.3261 11.1107C13.1649 11.4115 13.9321 11.871 14.5821 12.462C15.2321 13.0529 15.7516 13.7632 16.1097 14.5505C16.4678 15.3379 16.6573 16.1863 16.6668 17.0454L16.6668 17.0515C16.6668 17.3726 16.6668 18.3332 15.5557 18.3332H4.44461C3.3335 18.3332 3.3335 17.3666 3.3335 17.0455C3.35124 15.4101 4.02014 13.8431 5.20314 12.6654C6.38614 11.4878 7.99374 10.7886 9.69667 10.7111L9.69929 10.711ZM9.75285 11.8726C8.35939 11.9364 7.044 12.5086 6.07595 13.4723C5.10831 14.4356 4.56091 15.717 4.54561 17.0546V17.1704H15.4547V17.0547C15.4465 16.3526 15.2915 15.6592 14.9987 15.0156C14.7056 14.371 14.2804 13.7897 13.7483 13.306C13.2163 12.8223 12.5883 12.4461 11.9017 12.1999C11.2155 11.9538 10.4847 11.8425 9.75285 11.8726Z"
      fill="#525866"
    />
  </svg>
);

export const TeknoLogoAsBg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={120}
    height={92}
    viewBox="0 0 120 92"
    fill="none"
  >
    <g opacity={0.5}>
      <path
        d="M41.9537 23.4949H0L3.71795 34.1613H44.4537L41.9537 23.4949Z"
        fill="#01B7FF"
      />
      <path
        d="M71.9755 23.4949L56.9251 120L80.6617 34.1613H117.051L120 23.4949H71.9755Z"
        fill="#01B7FF"
      />
      <path
        d="M0 23.4949H41.9537L44.4537 34.1613L56.9251 120L71.9755 23.4949H120V0H96.5046H56.9251H0V23.4949Z"
        fill="#017AFF"
      />
    </g>
  </svg>
);

export const LoginPageBgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={186}
    height={141}
    viewBox="0 0 186 141"
    fill="none"
    {...props}
  >
    <g opacity={0.7}>
      <path
        d="M17.838 -9.48798C22.1265 -11.9216 27.4372 -11.8785 31.7769 -9.37485L70.7649 13.1182C75.1309 15.6371 77.8474 20.2617 77.8837 25.2375L78.2083 69.6712C78.2444 74.617 75.627 79.1591 71.3385 81.5927L32.567 103.595C28.2785 106.028 22.9677 105.985 18.628 103.482L-20.36 80.9884C-24.726 78.4696 -27.4425 73.845 -27.4788 68.8691L-27.8034 24.4355C-27.8395 19.4897 -25.2221 14.9476 -20.9336 12.5139L17.838 -9.48798Z"
        fill="url(#paint0_linear_1585_10340)"
      />
      <path
        d="M113.507 68.6558C115.855 67.3232 118.763 67.3468 121.139 68.7177L142.487 81.0341C144.878 82.4133 146.365 84.9455 146.385 87.6701L146.563 112C146.583 114.708 145.15 117.195 142.801 118.528L121.572 130.575C119.223 131.908 116.315 131.884 113.939 130.513L92.5908 118.197C90.2002 116.818 88.7127 114.286 88.6928 111.561L88.5151 87.2309C88.4953 84.5228 89.9285 82.0357 92.2767 80.7032L113.507 68.6558Z"
        fill="url(#paint1_linear_1585_10340)"
      />
      <path
        d="M162.467 27.9712C163.641 27.3049 165.095 27.3167 166.283 28.0022L176.957 34.1603C178.153 34.85 178.897 36.1161 178.906 37.4784L178.995 49.6434C179.005 50.9975 178.289 52.241 177.115 52.9073L166.5 58.931C165.326 59.5973 163.872 59.5855 162.683 58.9L152.009 52.7419C150.814 52.0522 150.07 50.7861 150.06 49.4238L149.971 37.2588C149.962 35.9047 150.678 34.6612 151.852 33.9949L162.467 27.9712Z"
        fill="#108BDE"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1585_10340"
        x1={-1.54783}
        y1={1.51298}
        x2={50.5802}
        y2={93.3726}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#31ACFF" />
        <stop offset={1} stopColor="#088FEB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1585_10340"
        x1={145.325}
        y1={83.8476}
        x2={90.2363}
        y2={116.207}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#62ADED" />
        <stop offset={1} stopColor="#019FFF" />
      </linearGradient>
    </defs>
  </svg>
);

export const MyResumesAllFilesBtnIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={19}
    viewBox="0 0 18 19"
    fill="none"
  >
    <path
      d="M12.75 5.0752H2.25M15.75 9.57519H2.25M11.325 14.0002H2.25"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const MyResumesDraftsBtnIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={19}
    viewBox="0 0 18 19"
    fill="none"
  >
    <path
      d="M11.25 2.75V7.25H15.75M11.625 2.75H3.75C3.35218 2.75 2.97064 2.90804 2.68934 3.18934C2.40804 3.47064 2.25 3.85218 2.25 4.25V14.75C2.25 15.575 2.925 16.25 3.75 16.25H14.25C14.6478 16.25 15.0294 16.092 15.3107 15.8107C15.592 15.5294 15.75 15.1478 15.75 14.75V6.875L11.625 2.75Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const MyResumesArchivedBtnIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={19}
    viewBox="0 0 18 19"
    fill="none"
  >
    <path
      d="M3 6.5V14.75C3 15.1478 3.15804 15.5294 3.43934 15.8107C3.72064 16.092 4.10218 16.25 4.5 16.25H13.5C13.8978 16.25 14.2794 16.092 14.5607 15.8107C14.842 15.5294 15 15.1478 15 14.75V6.5M7.5 9.5H10.5M2.25 2.75H15.75C16.1642 2.75 16.5 3.08579 16.5 3.5V5.75C16.5 6.16421 16.1642 6.5 15.75 6.5H2.25C1.83579 6.5 1.5 6.16421 1.5 5.75V3.5C1.5 3.08579 1.83579 2.75 2.25 2.75Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const WordIcon = (props: any) => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.88888 3V7H5.94444L4.99999 6L4.05555 7H3.11111V3H4.05555V5.5L4.99999 4.5L5.94444 5.5V3H6.41666V1H1.69444V9H8.30555V3H6.88888Z"
      fill="#5781E3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.22175 0C0.961083 0 0.75 0.222 0.75 0.496V9.504C0.750989 9.63531 0.800725 9.76094 0.888464 9.85374C0.976202 9.94655 1.0949 9.99909 1.21892 10H8.78107C8.84309 9.99954 8.90441 9.98616 8.96153 9.9606C9.01866 9.93505 9.07048 9.89784 9.11402 9.85108C9.15756 9.80433 9.19198 9.74895 9.21531 9.68811C9.23864 9.62727 9.25042 9.56216 9.24999 9.4965V2.5L6.88888 0H1.22175ZM8.30555 3H6.88888H6.41666V1H1.69444V9H8.30555V3Z"
      fill="#5781E3"
    />
  </svg>
)

export const PDFIcon = (props: any) => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.69444 1H6.41667V3H8.30556V9H1.69444V1ZM3.71316 5.3267C4.07643 4.46865 4.29162 3.53874 4.29162 2.75H5.23606C5.23606 3.99497 6.24832 5.3302 7.51043 5.77565L7.29429 6.745C5.81378 6.52105 4.28321 7.1902 2.9008 8.16605L2.34386 7.35955C2.85679 6.9251 3.34858 6.18785 3.71316 5.3267Z"
      fill="#DC3232"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.41667 1H1.69444V9H8.30556V3H6.41667V1ZM0.75 0.4959C0.75 0.222025 0.961315 0 1.22151 0H6.88889L9.24986 2.5L9.25 9.49625C9.25 9.77445 9.03991 10 8.78089 10H1.21911C0.960026 10 0.75 9.77235 0.75 9.5041V0.4959Z"
      fill="#DC3232"
    />
    <path
      d="M4.57495 5.7358C4.70123 5.4376 4.81309 5.12815 4.9073 4.81425C5.13 5.17655 5.40389 5.5091 5.7131 5.79765C5.24948 5.88555 4.79529 6.0298 4.35551 6.2138C4.43329 6.05715 4.50658 5.8974 4.57495 5.7358Z"
      fill="#DC3232"
    />
  </svg>
)