import React from 'react';

import styles from './Template2.module.css';
import { isValueExist } from '../components/utils';
import { useSelector } from 'react-redux';

interface ResumePreviewProps {
  resumeDetails: any;
  wrapperRef: any;
  selectedScreen: any;
}

const Template2 = (props: any) => {  
  const resumeDetails = useSelector((state:any) => state.extractedData);

  return (
    <div className={styles["modern-resume"]}>
      <div className={styles.header}>
        <h1>{resumeDetails?.name?.firstName} {resumeDetails?.name?.lastName}</h1>
        <h2>{resumeDetails?.designation}</h2>
      </div>

      <div className={styles["contact-info"]}>
        <p>Email: {resumeDetails?.email}</p>
        <p>Phone: {resumeDetails?.phone?.join(", ")}</p>
      </div>

      <div className={styles["main-content"]}>
        {isValueExist(resumeDetails?.careerObjective) && <div id="career_objective" className={styles["career-objective-section"]}>
          <h2>Career Objective: </h2>
          <div>
            {resumeDetails?.careerObjective}
          </div>
        </div>}

        <div className={styles["skills-section"]}>
          <h2>Skills</h2>
          {resumeDetails?.toolsTechnicalSkills && typeof resumeDetails?.toolsTechnicalSkills === "string" ? resumeDetails?.toolsTechnicalSkills : resumeDetails?.toolsTechnicalSkills?.map((skill: string, index: number) => (
            <div className={styles["skill"]} key={index}>{skill}</div>
          ))}
        </div>

        <div className={styles["experience-section"]}>
          <h2>Experience</h2>
          {resumeDetails?.workExperiences && resumeDetails?.workExperiences.map((workExperience: any, index: number) => (
            <div className={styles["experience"]} key={index}>
              <h3>{workExperience.role} at {workExperience.company}</h3>
              <p>{workExperience.startDate} - {workExperience.endDate}</p>
              <ul>
                {workExperience.keyResponsibilities && workExperience.keyResponsibilities.split('\n').map((resp: string, i: number) => (
                  <li key={i}>{resp}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className={styles["education-section"]}>
          <h2>Education</h2>
          {resumeDetails?.educationalDetails && resumeDetails?.educationalDetails.map((education: any, index: number) => (
            <div className={styles.education} key={index}>
              <h3>{education.Degree} in {education.Specialization}</h3>
              <p>{education["University/College"]}</p>
              <p>GPA {education.GPA}</p>
              <p>{education["Start Date"]} - {education["End Date"]}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Template2;