import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import { useNavigate } from 'react-router-dom';
// import {ReactComponent as ProfilePhotoImage} from "../../assets/SVG/Upload-photo-big.svg";
// import {ReactComponent as ProfilePhotoImage} from "../../assets/SVG/personal-details-profile.svg";
 import {ReactComponent as ProfilePhotoImage} from "../../assets/SVG/personal-details-profile.svg";
import {ReactComponent as PersonalSiteIcon} from "../../assets/SVG/websiteOutlined.svg";
import {ReactComponent as LinkedInIcon} from "../../assets/SVG/linkedinOutlined.svg";
import {ReactComponent as GithubIcon} from "../../assets/SVG/gitHubOutlined.svg";
import {ReactComponent as CustomLink} from "../../assets/SVG/customeLink.svg";
import TextField from '@mui/material/TextField';
import { autoComplteStyle, InputFieldCustomeStyle, InputSelectCustomeStyle } from './ProcessResumeUtils';
import InputAdornment from '@mui/material/InputAdornment';
import { useSelector, useDispatch } from 'react-redux';
import { setExtractedData } from '../../redux/actions';
// import defaultProfilePicture from "./../../assets/profile_picture.png";
import defaultProfilePicture from "./../../assets/newProfile.png";
import { Autocomplete, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { statesData } from '../../Data/skillsData';
import { useState } from 'react';
import CustomLinkEditor from './CustomLinkEditor';
import { v4 as uuidv4 } from 'uuid';


const PersonalDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state,setState]=useState();
  const [country, setCountry] = useState('USA');
  const [customLinks,setCustomLinks] = useState([
    {
      id:uuidv4(),
      name:"Reddit link(Custom)",
      isEdit:false
    }
  ])
  const extractedData = useSelector((state:any) => state.extractedData);

  if(!extractedData?.profile_Picture) {
    dispatch(setExtractedData({ ...extractedData, profile_Picture: defaultProfilePicture }));
  }

  const handleCountryChange = (event: SelectChangeEvent) => {
    setCountry(event.target.value as string);
  };
  

  const handleStateChange = (e:any,newValue:any) => {
    setState(newValue);
}

  const handleChange = (newValue: any, key: string) => {
    dispatch(setExtractedData({ ...extractedData, [key]: newValue }));
  };

  const handleSocialLinkChange = (newValue:string,key:string) => {
    dispatch(setExtractedData({ ...extractedData, socialLinks:{...extractedData.socialLinks,[key]: newValue }}));
  }

  const handlePhoneChange = (index: number, newValue: string) => {
    const updatedPhones = [...extractedData.phone];
    updatedPhones[index] = newValue;
    handleChange(updatedPhones, 'phone')
  };

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          dispatch(setExtractedData({ ...extractedData, profile_Picture: reader.result.toString() }));
        } else {
          console.error('Failed to read file');
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLinkNameChange = (name: string, id:string) => {
    const updatedLinksList = customLinks?.map((link)=> {
      if(link?.id===id) {
        return {
          ...link,
          isEdit:false,
          name,
        }
      }
      return link;
    } )
    setCustomLinks(updatedLinksList);
  }

  const addCustomLink = () => {
    setCustomLinks((prev)=>([...prev,{name:`Custom link ${customLinks?.length+1}`,isEdit:false,id:uuidv4()}]));
  }

  const deleteCustomLink = (id:string,linkName:string) => {
    const linksList = extractedData?.socialLinks;
    delete linksList?.[linkName]
    dispatch(setExtractedData({ ...extractedData, socialLinks:linksList}));
    const updatedLinks = customLinks?.filter((link)=> link.id!==id);
    setCustomLinks(updatedLinks);
  }

  const handleEditStart = (id:string) => {
    const updatedLinks = customLinks?.map((link)=> {
      if(link.id === id){
        return {
          ...link,
          isEdit:true,
        }
      }
      return link; 
    });
    setCustomLinks(updatedLinks);
  }




  return (
      <Box className="personal-details-container">
          <Box className="personal-details-wrapper">
            <Box className="personal-details-content">
              <Box className="main-heading">
                      <Box className="heading-box">
                          <Typography variant="h5" color="color.primary" className="heading">
                              Personal Details
                          </Typography>
                      </Box>
              </Box>

              <Box className="profile-info">
                  <Box className="icon-cont">
                      <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="upload-photo"
                        onChange={handleImageChange}
                      />
                      <label htmlFor="upload-photo">
                        <Box className="icon-cont" style={{ cursor: 'pointer' }}>
                          {extractedData?.profile_Picture ? <img src={extractedData?.profile_Picture} alt="Uploaded" style={{maxWidth: "100px"}}/> : <ProfilePhotoImage/>}
                        </Box>
                      </label>
                      <Box className="text-wrapper">
                      <Typography variant="body2" className="color-primary">Upload Photo</Typography>  
                      </Box>
                  </Box>
              </Box>

              <Box className="profile-details">
                  <Box className="input-box-container">
                    <InputLabel htmlFor="firstName">
                      First Name*
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="firstName"
                      value={extractedData?.name?.firstName}
                      onChange={(e) => handleChange({ ...extractedData?.name, firstName: e.target.value }, 'name')}
                      
                      placeholder="Ex: John"
                    />
                  </Box>
                  <Box className="input-box-container">
                    <InputLabel htmlFor="lastName">
                      Last Name*
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="lastName"
                      value={extractedData?.name?.lastName}
                      onChange={(e) => handleChange({ ...extractedData?.name, lastName: e.target.value }, 'name')}
                      
                      placeholder="Ex: Doe"
                    />
                  </Box>

                  <Box className="input-box-container">
                      <InputLabel htmlFor="email">
                          Email*
                      </InputLabel>
                      <TextField
                        fullWidth
                        id="email"
                        value={extractedData?.email}
                        onChange={(e) => handleChange(e.target.value, 'email')}
                        
                      />
                  </Box>

                  <Box className="input-box-container">
                      <InputLabel htmlFor="designation">
                          Designation*
                      </InputLabel>
                      <TextField
                        fullWidth
                        id="designation"
                        value={extractedData?.designation}
                        onChange={(e) => handleChange(e.target.value, 'designation')}
                        
                      />
                  </Box>

                  {extractedData?.phone?.map((phone: number, index: number) => (
                    <Box key={index} className="input-box-container">
                      <InputLabel htmlFor={`phone-${index}`}> 
                        Phone {index + 1}
                      </InputLabel>
                      <TextField
                        fullWidth
                        id={`phone-${index}`}
                        value={phone}
                        onChange={(e) => handlePhoneChange(index, e.target.value)}
                        
                      />
                    </Box>
                  ))}

                  <Box className="input-box-container">
                  <InputLabel htmlFor="designation">  
                          Country*
                      </InputLabel>
                      <Select
                          value={country}
                          onChange={handleCountryChange}
                          fullWidth
                        >
                          <MenuItem value={"USA"}>USA</MenuItem>
                          <MenuItem value={"INDIA"}>INDIA</MenuItem>
                        </Select>
                  </Box>

                  <Box className="input-box-container">
                      <InputLabel htmlFor="designation">
                          State/City*
                      </InputLabel>
                             <Autocomplete
                            multiple={false}
                            id="hobbies"
                            fullWidth
                            options={statesData}
                            value={state}
                            onChange={handleStateChange}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Ex.Football"
                            />
                            )}
                            />
                  </Box>
              </Box>

              <Box className="address-container">
              <Box className="input-box-container">
                      <InputLabel htmlFor="address" > 
                          Address
                      </InputLabel>
                      <TextField
                        multiline
                        rows={2}
                        fullWidth
                        id="address"
                        value={extractedData?.location}
                        onChange={(e) => handleChange(e.target.value, 'location')}
                      />
                  </Box>
              </Box>

              {/* <Box className="professional-summary">
                <Typography variant="h5" color="color.primary" className="heading">Professinal Summary</Typography>
                <Box className="input-box-container">
                      <TextField
                        multiline
                        rows={4}
                        fullWidth
                        id="career-objective"
                        
                        value={extractedData?.careerObjective}
                        onChange={(e) => handleChange(e.target.value, 'careerObjective')}
                      />
                  </Box>
              </Box> */}

              <Box className="links">
                <Typography variant="h5" color="color.primary" className="heading">Social Links</Typography>
                <Box className="input-box-container">
                      <InputLabel htmlFor="personalSite"> 
                          Personal Site
                      </InputLabel>
                      <TextField
                          fullWidth
                          id="personalSite"
                          
                          placeholder="www.mysite.com"
                          value={extractedData?.socialLinks?.personal_Website !== "NA" ? extractedData?.socialLinks?.personal_Website : ""}
                          onChange={(e) => handleSocialLinkChange( e.target.value , 'personal_Website')}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PersonalSiteIcon />
                              </InputAdornment>
                            ),
                          }}
                          />
                  </Box>

                  <Box className="input-box-container">
                      <InputLabel htmlFor="linkedIn" > 
                          LinkedIn
                      </InputLabel>
                      <TextField
                          fullWidth
                          id="linkedIn"
                          
                          placeholder="www.linkedin.com/username"
                          value={extractedData?.socialLinks?.linkedIn_URL !== "NA" ? extractedData?.socialLinks?.linkedIn_URL : ""}
                          onChange={(e) => handleSocialLinkChange( e.target.value , 'linkedIn_URL')}
                          InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LinkedInIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                  </Box>

                  <Box className="input-box-container">
                      <InputLabel htmlFor="gitHub">
                          GitHub
                      </InputLabel>
                      <TextField
                          fullWidth
                          id="gitHub"
                          
                          placeholder="www.github.com/username"
                          value={extractedData?.socialLinks?.github_URL !== "NA" ? extractedData?.socialLinks?.github_URL : ""}
                          onChange={(e) => handleSocialLinkChange( e.target.value , 'github_URL')}
                          InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <GithubIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                  </Box>

                  <>
                  </>
                  {customLinks?.map((link,i)=>(
                    <Box className="input-box-container">
                    <CustomLinkEditor name={link.name} id={link.id} isEdit={link.isEdit} handleLinkNameChange={handleLinkNameChange} deleteCustomLink={deleteCustomLink} handleEditStart={handleEditStart}/>
                    <TextField
                        fullWidth
                        id={link.name}
                        placeholder="www.personalwebsite.com/username"
                        value={extractedData?.socialLinks?.[link.name]}
                        onChange={(e) => handleSocialLinkChange( e.target.value ,link.name)}
                        InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <CustomLink />
                              </InputAdornment>
                            ),
                          }}
                        />
                </Box>
                  ))}

                  <Box className="add-link">
                    <Typography variant="body2" className="link color-primary" onClick={addCustomLink}>Add Link</Typography>
                  </Box>
              </Box>
            </Box>
          </Box>
      </Box>
  )
};

export default PersonalDetails;