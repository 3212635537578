import { Autocomplete, Box, Chip, createFilterOptions, TextField, Typography } from "@mui/material";
import { hobbiesData, skillOption } from "../../../Data/skillsData";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setExtractedData } from "../../../redux/actions";
import { StateProps } from "../../../redux/reducer";

interface HobbiesProps {
    handleSectionDelete:(section:string) =>void;
    sectionName:string;
}

const filter = createFilterOptions<skillOption>();

const getNewHobby = (hooby:string) => {
    return {
        label: hooby,
        value: hooby
    }
}

const Hobbies:React.FC<HobbiesProps> = ({handleSectionDelete,sectionName}) => {
    const extractedData = useSelector((state:StateProps) => state.extractedData);
    const [hobbies, setHobbies] = useState<skillOption[]>([]);
    const [availableHobbies, setAvailableHobbies] = useState(hobbiesData);
    const dispatch = useDispatch();

    const handleHobbiesChange = (event:any, newValue:any[]) => {
        setHobbies(newValue);
        dispatch(setExtractedData({
          ...extractedData,
          hobbies:newValue?.map((hobby)=> hobby?.label),
        }));
        const updatedAvailableHobbies = hobbiesData.filter(hb => !newValue.some(selectedHb => selectedHb.value === hb.value));
        setAvailableHobbies(updatedAvailableHobbies);
      };

      const handleHobbyDelete = (hbToDelete: string) => {
        const updatedHobbies = hobbies.filter(hb => hb.value !== hbToDelete);
        const deletedHobby = hobbiesData.find(skill => skill.value === hbToDelete);
        if (deletedHobby) {
          setAvailableHobbies([...availableHobbies, deletedHobby]);
        }
        setHobbies(updatedHobbies);
        dispatch(setExtractedData({
          ...extractedData,
          hobbies:updatedHobbies?.map((hobby)=> hobby?.label),
        }));
      };

    return (
        <Box className="hobbies-section"> 
        <Box className="heading-box">
            <Typography variant="h5" color="color.primary" className="heading">
            Hobbies
            </Typography>
            <Typography className="sub-heading" onClick={()=>handleSectionDelete(sectionName)}>Remove section</Typography>
        </Box>

        <Box className="input-box-container">
              <Autocomplete
                multiple
                id="hobbies"
                fullWidth
                options={availableHobbies} // Use updated available skills
                //@ts-ignore
                value={hobbies}
                //value={skills.map(({label,value}) => ({label,value}))}
                onChange={handleHobbiesChange}
                renderTags={()=>null}
                renderOption={(props, option,newValue) => 
                  {
                    if(option?.label?.includes("Add ")){
                      const newStr = option.label.replace(/^Add\s*/, "");
                        return <li {...props} style={{ display: 'flex', alignItems: 'center' }}>
                          <span style={{color:"#5594ff",marginRight:"0.25rem"}}>Add </span>{newStr}
                      </li>
                    }

                  return <li {...props} style={{ display: 'flex', alignItems: 'center' }}>
                    {option.label}
                  </li>
                  }
                }
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
            
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.label);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        value: inputValue,
                        label: `Add "${inputValue}"`,
                      });
                    }
            
                    return filtered;
                  }}
                freeSolo
                renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder="Ex.Swimming"
                />
                )}                  
                />

        </Box>

       {hobbies.length > 0 && <Box
          sx={{
            marginTop:1,
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1.4,
            padding:0,
            borderRadius: '8px',
          }}
        >
          {hobbies?.map((hb, index) => (
              <Box>
                <Chip
                  sx={{
                  height:"2.2rem",
                  fontSize: '14px', // Adjust font size
                  fontWeight:400,
                  padding: '0.3rem', // Adjust padding
                  border: '1px solid #e2e8f0', // Add border
                  borderRadius: '8px', // Optional: Adjust border radius
                    '& .MuiChip-deleteIcon': {
                      fontSize: '16px', // Adjust the size of the delete icon
                    },
                }}  
                  label={hb.value}
                  variant="outlined"
                  onDelete={()=> handleHobbyDelete(hb.value)}
                />
              </Box>

            ))
          }
        </Box>}    
        </Box>
    )
}
export default Hobbies;