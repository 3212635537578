import  Box  from '@mui/material/Box';
import  Paper  from '@mui/material/Paper';
import ProcessResumePreviewHeader from './ProcessResumePreviewHeader';
import ProcessResumePreview from './ProcessResumePreview';
import ResumeScoreProgressBar from './ResumeScoreProgressBar';
import { Routes, Route, useNavigate } from 'react-router-dom';
import UploadProfilePhoto from './EditForms/UploadProfilePhoto';
import PersonalDetails from './EditForms/PersonalDetails';
import WorkExperience from './EditForms/WorkExperience';
import Education from './EditForms/Education';
import Skills from './EditForms/Skills';
import { Button, createTheme, useMediaQuery, useTheme } from '@mui/material';
import ProcessResumeFooter from './ProcessResumeFooter';
import ResumeProgressStepper from './ResumeProgressStepper';
import AppBarHeader from './AppBarHeader';
import SideDrawer from './SideDrawer';
import NameScoreWrapper from './NameScoreWrapper';
import Summary from './EditForms/Summary';
import Header from '../components/common/Header';
import EducationList from './EditForms/Education/EducationList';
import WorkExperienceList from './EditForms/WorkExperience/WorkExperienceList';
import TailoredResumeSuggestions from './TailoredResumeSuggestions';
import { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StateProps } from '../redux/reducer';
import { setPreviewResumeData } from '../redux/actions';
import { useWindowSize } from '../components/CommonComponents/windowResize';

const ProcessResumeFeature:React.FC = () => {
const dispatch = useDispatch();
const previewResumeData = useSelector((state:StateProps)=> state?.previewResumeData);

const {isXs,isSm,isMd,isLg,isXl,windowSize} = useWindowSize() ;

useLayoutEffect(()=>{
  console.log({isXs,isSm,isMd,isLg,isXl},"from parent Effect")
    if(isXs || isSm || isMd || isLg) {
        dispatch(setPreviewResumeData({...previewResumeData,multiplicationFactor:0.6}))
    }
    else if(isXl) {
        dispatch(setPreviewResumeData({...previewResumeData,multiplicationFactor:0.75}))
    }
},[isXs,isSm,isMd,isLg,isXl,dispatch,windowSize?.width,windowSize?.height]);

    return (
      <Box className="process-resume-page-container">
        <Box className="app-bar-container">
          <AppBarHeader/>
        </Box>

        <Box className="main-content">
          <Box className="side-drawer-container">
            <SideDrawer />
          </Box>
        <Paper className="left-edit-container" sx={{
          flex:{
              xs: '1.65',  // for extra-small screens (0px - 600px)
              sm: '1.65',   // for small screens (600px - 960px)
              md: '2',   // for medium screens (960px - 1280px)
              lg: '1.65',   // for large screens (1280px - 1920px)
              xl: '1',   // for extra-large screens (1920px and up)
          }
        }}>
          <Box className="left-progress-bar">
            <NameScoreWrapper/>
            <ResumeProgressStepper/>
          </Box>
          <TailoredResumeSuggestions/>
          <Box className="left-edit-fields-container">
            <Routes>
                <Route path="profile-photo" element={<UploadProfilePhoto/>} />
                <Route path="personal-details" element={<PersonalDetails/>} />
                <Route path="work-experience" element={<WorkExperienceList/>} />
                <Route path="education" element={<EducationList/>} />
                <Route path="skills" element={<Skills/>} />
                <Route path="summary" element={<Summary/>} />
            </Routes>
          </Box>
          
          <ProcessResumeFooter/>
        </Paper>

          <Box className="right-preview-container">
              <Box className="right-preview-header-container">
                  <ProcessResumePreviewHeader/>
              </Box>
              <Box className="right-preview-content-container">
                  <ProcessResumePreview/>
              </Box>
          </Box>
        </Box>
        </Box>
    )
}

export default ProcessResumeFeature;