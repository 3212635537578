import { Box, IconButton, InputLabel, TextField, Typography } from "@mui/material"
import DoneIcon from '@mui/icons-material/Done';
import { useEffect, useState } from "react";
import {ReactComponent as CustomEditLinkIcon} from "../../assets/SVG/link-edit-icon.svg";

interface ICustomLinkEditor {
    name:string;
    id:string;
    isEdit:boolean;
    handleLinkNameChange:(name:string,id:string)=>void;
    deleteCustomLink:(id:string,name:string) => void;
    handleEditStart:(id:string)=>void
};

const CustomLinkEditor:React.FC<ICustomLinkEditor> = ({name,id,isEdit,handleLinkNameChange,deleteCustomLink,handleEditStart}) => {
    const [linkName,setName] = useState<string>("");
    //const [edit,setEdit] = useState<boolean>(isEdit);

    useEffect(()=>{
       if(isEdit){
        setName(name)
       }
    },[isEdit])

    const handleDone = () => {
        handleLinkNameChange(linkName, id);
        // setEdit(false)
    }
    return(
        <>
        {!isEdit ? 
        <Box sx={{display:"flex",justifyContent:"left",alignItems:"center",gap:"0.5rem"}}>
        <InputLabel htmlFor={name} > 
            {name}
        </InputLabel>
        <Box sx={{cursor:"pointer"}}>
        <CustomEditLinkIcon onClick={()=>handleEditStart(id)}/>
        </Box>
        <Typography sx={{flex:"1",textAlign:"right",paddingRight:"0.5rem",cursor:"pointer",fontSize:"14px",color:"#ef4444"}}
        onClick={()=>deleteCustomLink(id,name)}
        >Delete</Typography>
        </Box>
        : <Box className="edit-wrapper" sx={{display:"flex",justifyContent:"left",alignItems:"center"}}>
        <TextField onChange={(e)=> setName(e.target.value)} value={linkName} size="small" sx={{ 
            '& .MuiOutlinedInput-root': {
            padding:"0.2rem 0.2rem 0.2rem 0.5rem  !important",
            borderRadius: '1px',
            '& fieldset': {
             paddingLeft:"0.5rem !important",
          },
            }
        }} 
          />
        <IconButton disabled={!linkName}><DoneIcon onClick={handleDone}/></IconButton>
        <Typography sx={{flex:"1",textAlign:"right",paddingRight:"0.5rem",cursor:"pointer",fontSize:"14px",color:"#ef4444"}}
        onClick={()=>deleteCustomLink(id,name)}
        >Delete</Typography>
        </Box>}
        </>
    )
}

export default CustomLinkEditor