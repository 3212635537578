import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CourseType } from "../../../../components/utils";
import EducationCard from "../../EducationCard";
import { useDispatch, useSelector } from "react-redux";
import { StateProps } from "../../../../redux/reducer";
import { setExtractedData } from "../../../../redux/actions";
import SectionEntryModal from "../SectionEntryModal";

interface CourseCardProps {
    courseData:CourseType;
    coursesList:CourseType[];
    setCoursesList:Dispatch<SetStateAction<CourseType[]>>
  }

export interface ISectionEntryModalData {
  modalState:boolean;
  index:number;
  entryDelete:boolean;
}


const CourseCard:React.FC<CourseCardProps> = ({courseData,coursesList,setCoursesList}) => {
  const [sectionEntryModal,setSectionEntryModal] = useState<ISectionEntryModalData>({modalState:false,index:0, entryDelete:false});
    const dispatch = useDispatch();
    const extractedData = useSelector((state:StateProps) => state.extractedData);

    useEffect(()=>{
      if(sectionEntryModal?.modalState && sectionEntryModal?.entryDelete){
        const updatedCourses = coursesList?.filter(course=> course?.index !== sectionEntryModal?.index)?.map((course) => {
          delete course.index;
          return course;
         })
         dispatch(setExtractedData({
           ...extractedData,
           courses:updatedCourses,
         }));
      }
      setSectionEntryModal({modalState:false,index:0, entryDelete:false})
    },[sectionEntryModal?.entryDelete])

    const handleEdit = (index:number|undefined) => {
        setCoursesList((prev) => prev?.map((course) => {
         if(index===course?.index) {
           return {...course,isEdit:true}
         }
         return course;
       }))
     }

     const handleDelete = (index:number|undefined) => {
      setSectionEntryModal((prev)=> ({...prev,modalState:true,index:index ?? 0}))
     }

  return (
    <>
          <EducationCard details={{heading:courseData?.organization,
        startDate:courseData?.startDate ,
        endDate:courseData?.endDate,
        aboutText:courseData.courseDescription,
        index:courseData?.index
    }}
        handleEditClick={()=>handleEdit(courseData?.index)}
        handleDeleteClick={()=>handleDelete(courseData?.index)}
        />
      <SectionEntryModal sectionEntryModal={sectionEntryModal} setSectionEntryModal={setSectionEntryModal}/>
    </>
  )
}
export default CourseCard;