import { Box, Button, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {ReactComponent as DatePickerIcon} from "../../../assets/SVG/date-picker.svg";
import { Dispatch, SetStateAction, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateProps } from "../../../redux/reducer";
import { isValidUrl } from "../../../components/common/CommonFunctions";
import { setExtractedData } from "../../../redux/actions";
import { EducationTypes, WorkExperienceTypes } from "../../../components/utils";
import ReactQuill from "react-quill";
import { quillModules } from "../WorkExperience";

  interface CourseProps {
    courseData:WorkExperienceTypes;
    coursesList:WorkExperienceTypes[];
    setCoursesList:Dispatch<SetStateAction<WorkExperienceTypes[]>>
  }

const WorkExperienceEdit:React.FC<CourseProps> = ({courseData,coursesList,setCoursesList}) => {

    const dispatch = useDispatch();
    const extractedData = useSelector((state:StateProps) => state.extractedData);
    //formData handles local form state
    const [formData, setFormData] = useState<WorkExperienceTypes>(courseData);
    const [errors,setErrors] = useState({
        role: "",
        startDate:"",
        endDate:"",
        company:"",
      });
    const handleChange = (newValue: any, key: string) => {

          //If already showing error for the field to clear that
            setErrors((prev)=> ({
              ...prev,
              [key]:"",
            }))
            setFormData({
                ...formData,
                [key]: newValue,
              });
      };
    
      const handleStartDateChange = (newValue: Date | null) => {
        if(newValue === null) return;
        const date = new Date(newValue);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        
        const formattedDate = `${year}-${month}-${day}`;
        handleChange(formattedDate, 'startDate');
        if (newValue && formData.endDate !== null && newValue > (formData.endDate as any)) {
          handleChange(null, 'endDate');
        }
      };
    
      const handleEndDateChange = (newValue: Date | null) => {
        if(newValue === null) return;
        const date = new Date(newValue);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        
        const formattedDate = `${year}-${month}-${day}`;
        handleChange(formattedDate, 'endDate');
      };

      const handleCancel = () => {
        if(!!formData?.isNewEntry) {
            setCoursesList((prev) => prev ? prev.slice(0, -1) : prev)
        }
        else {
            setCoursesList((prev)=> prev.map((courEle) => {
                if(courEle?.role === courseData.role 
                    && courEle?.company === courseData.company
                    && courEle?.startDate === courseData?.startDate 
                    && courEle?.endDate === courseData?.endDate) {
                    return {...courEle, isEdit: false};
                }
                return courEle;
            }));
        }
      }
  
      const handleSave = () => {
        if(!formData?.company) {
          setErrors((prev)=>({
            ...prev,
            company:"Organization is required"
          }))
        }
        if(!formData?.startDate){
          setErrors((prev)=>({
            ...prev,
            startDate:"Start Date is required"
          }))
        }
        if(!formData?.endDate) {
          setErrors((prev)=>({
            ...prev,
            endDate:"End Date is required"
          }))
        }
        if(!formData?.role) {
            setErrors((prev)=>({
              ...prev,
              role:"Job Title is required"
            }))
          }

        if(formData?.role && formData?.company && formData?.startDate && formData?.endDate) {
            //perform new course entry
            if(formData?.isNewEntry) {
                /**
                 * New Entry
                */
               delete formData?.isNewEntry;
               delete formData?.index;
                dispatch(setExtractedData({
                  ...extractedData,
                  workExperiences: [
                    ...extractedData.workExperiences ?? [],
                     {...formData,isEdit:false}
                  ],
                }));
              }
              else {
                const updatedCourses = coursesList?.map((course)=> {
                    if(course?.index === formData?.index) {
                      delete formData?.index;
                        return {...formData,isEdit:false};
                    }
                    delete course?.index;
                    return course;
                })
                dispatch(setExtractedData({
                  ...extractedData,
                  workExperiences:updatedCourses,
                }));
              }
              //both cases remove error
              setErrors({
                role: "",
                startDate:"",
                endDate:"",
                company:"",  
              })
          }
      };
           
   return (
    <Box className="section-item-container">
    <Box className="courses-edit-section common-edit-section">

    <Box className="courses-dates-container">
    <Box className="input-box-container">
        <InputLabel htmlFor="role">
            Job type
        </InputLabel>
        <Select
            value={formData?.jobType}
            onChange={(e) => handleChange(e.target.value, 'jobType')}
            fullWidth
        >
            <MenuItem value={"full_time"}>Full time</MenuItem>
            <MenuItem value={"part_time"}>Part time</MenuItem>
        </Select>
        </Box>

    <Box className="input-box-container">
    <InputLabel htmlFor="company">Organization*</InputLabel>
    <TextField
        fullWidth
        id="organization"
        name="organization"
        placeholder="Ex: Microsoft Corporation"
      value={formData?.company}
      onChange={(e) => handleChange(e.target.value, 'company')}
      error={!!errors.company}
      helperText={errors.company ? errors.company : ''}
    />
    </Box>
    </Box>

    <Box className="courses-dates-container">
    <Box className="input-box-container">
    <InputLabel htmlFor="company">Job Title*</InputLabel>
    <TextField
        fullWidth
        id="company"
        name="company"
        placeholder="Ex: Microsoft Corporation"
      value={formData?.role}
      onChange={(e) => handleChange(e.target.value, 'role')}
      error={!!errors.role}
      helperText={errors.role ? errors.role : ''}
    />
    </Box>

    <Box className="input-box-container">
    <InputLabel htmlFor="company">Location*</InputLabel>
    <TextField
        fullWidth
        id="company"
        name="company"
        placeholder="Ex: Microsoft Corporation"
      value={formData?.location}
      onChange={(e) => handleChange(e.target.value, 'location')}
    />
    </Box>
    </Box>

    <Box className="courses-dates-container">
    <Box className="input-box-container">
        <InputLabel htmlFor="startDate">
            Start Date
        </InputLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
        value={formData?.startDate as any}
        onChange={handleStartDateChange}
        slots={{
            openPickerIcon: DatePickerIcon,
            textField: (params) => (
                <TextField
                {...params}
                fullWidth
                id="startDate"
                name="startDate"
                value={formData?.startDate}
                error={!!errors.startDate}
                helperText={errors.startDate ? errors.startDate : ""}
                />
            ),
            }}
            />
    </LocalizationProvider>
    </Box>

    <Box className="input-box-container">
        <InputLabel htmlFor="endDate">
                End Date
        </InputLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
            value={formData?.endDate as any}
            onChange={handleEndDateChange}
            minDate={formData?.startDate ? formData?.startDate as any : undefined}
            slots={{
            openPickerIcon: DatePickerIcon,
            textField: (params) => (
                <TextField
                {...params}
                fullWidth
                id="endDate"
                name="endDate"
                value={formData?.endDate}
                error={!!errors?.endDate}
                helperText={errors?.endDate ? errors.endDate : ""}
                />
            ),
            }}
            /> 
        </LocalizationProvider>
    </Box>
    </Box>

    <Box className="input-box-container"> 
            <InputLabel htmlFor="address">
            Description
            </InputLabel>
            <Box className="quill-container">
                <ReactQuill 
                value={formData?.keyResponsibilities}
                onChange={(e: any) => handleChange(e, 'keyResponsibilities')}
                theme="snow" 
                modules={quillModules}
                />
            </Box>
    </Box>


    <Box className="section-action">
        <Box className="link-container">
        </Box>
        <Box className="button-container">
        <Button className="cancel-button" variant="outlined" size="medium" onClick={handleCancel}>Cancel</Button>
        <Button className="add-button" variant="contained" size="medium" onClick={handleSave}>Add</Button>
        </Box>
    </Box>
    </Box>
    </Box>

   )
}

export default WorkExperienceEdit;