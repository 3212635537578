import React, { useState } from 'react';
import { Box, Card, CardContent, CardHeader, Typography, List, ListItem, ListItemText, Divider, Grid, LinearProgress, Chip, Container, Button, Alert, AlertTitle, Accordion, AccordionSummary, AccordionDetails, linearProgressClasses, Avatar, Paper, CircularProgress, Dialog, DialogContent, DialogContentText, TextField, DialogTitle, DialogActions, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Header from '../common/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import WarningIcon from '@mui/icons-material/Warning';
import { styled } from '@mui/material/styles';
import { PriorityHigh } from '@mui/icons-material';
import { BulbIcon } from '../Icons';
import { Template1 } from '../../templates';
import AppBarHeader from '../../ProcessResume/AppBarHeader';
import SideDrawer from '../../ProcessResume/SideDrawer';
import { closePopover, openPopover, setExtractedData, setFixedSections, updatePopoverValue } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AuthInputFieldStyle } from '../../ProcessResume/EditForms/ProcessResumeUtils';
import { useRefContext } from '../../templates/TemplateSectionsRef';

const useStyles = makeStyles({
    root: {
        padding: '20px',
        overflowY: 'auto', // Enable vertical scrolling
        maxHeight: 'calc(100vh - 64px)', // Adjust height to fit within the viewport
    },
    card: {
        marginBottom: '20px',
        borderRadius: '15px',
    },
    sectionTitle: {
        marginBottom: '10px',
        fontWeight: 'bold',
        color: '#3f51b5',
    },
    progress: {
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: "#E2E8F0",
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: "#00BF58",
        },
        marginBottom: '10px',
    },
    listItem: {
        paddingLeft: '0px',
    },
    listItemText: {
        fontSize: '1rem',
    },
    divider: {
        margin: '20px 0',
    },
    chip: {
        margin: '5px',
    },
    backButton: {
      marginBottom: '20px',
    },
    resumeScorePanel: {
        border: '1px solid #E2E8F0',
        borderRadius: '12px',
        boxShadow: 'none'
    },
    resumeScorePanelHeader: {
        fontWeight: 'bolder',
    }
    // alert: {
    //     display: 'flex',
    //     justifyContent: 'space-between',
    //     alignItems: 'center',
    //     backgroundColor: '#fff3e0', // Custom background color for warning
    //     color: '#ff9800', // Custom text color for warning
    //     padding: '16px', // Custom padding
    // },
    // alertIcon: {
    //     marginLeft: '16px', // Custom margin for the icon
    // },
});

const CustomButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 14,
    padding: '8px 18px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: "#00BF58",
    borderColor: '#00BF58',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: "#00BF58",
        borderColor: '#00BF58',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: "#00BF58",
        borderColor: '#00BF58',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  });

/* const CircularProgress = ({ value }: any) => (
  <Card>
    <CardContent>
      <Typography variant="h6">Progress</Typography>
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" value={value} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(value)}%`}</Typography>
        </Box>
      </Box>
    </CardContent>
  </Card>
);
 */
const ATSCheckResult = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    let { atsCheckResult } = location.state || {};
    atsCheckResult = {
        "summary": {
            "atsCompatibilityScore": "75%",
            "keywordMetrics": {
                "keywordDensity": "2.3%",
                "keywordRelevance": "7/10",
                "topKeywords": [
                    "Software Engineer",
                    "RESTful APIs",
                    "Django",
                    "Python",
                    "JavaScript",
                    "NodeJS",
                    "SQL",
                    "Agile",
                    "Tableau",
                    "Machine Learning"
                ]
            },
            "resumeStructureAndFormat": {
                "clearHeadings": "90%",
                "bulletPointUsage": "85%",
                "whiteSpaceUsage": "8/10"
            },
            "contentAndRelevance": {
                "workExperienceRelevance": "8.5/10",
                "skillMatch": "80%",
                "educationRelevance": "9/10"
            },
            "readabilityAndClarity": {
                "readabilityScore": "65",
                "sentenceComplexity": "17 words",
                "jargonAndAcronyms": "15%"
            },
            "resumeObjectWithChanges": {
                "careerObjective": "Highly motivated software engineer with a proven track record of building scalable and efficient web applications. Seeking to leverage my expertise in RESTful APIs, Agile methodologies, and data visualization to contribute to a dynamic team.",
                "workExperiences": [
                    {
                        "index": 0,
                        "keyResponsibilities": [
                            "Re-architected a multi-page web app into a single-page web application, boosting yearly revenue by $1.4 million.",
                            "Developed the logic for a streamlined ad-serving platform, scaling it to 35 million users, which improved page speed by 15% after implementation.",
                            "Tested software for bugs and optimized performance, increasing efficiency by 18%.",
                            "Collaborated with a team of 4 engineers to iterate on the college admissions platform, creating new features across the software."
                        ],
                        "toolsTechnologies": [
                            "JavaScript",
                            "HTML",
                            "CSS",
                            "Agile",
                            "RESTful APIs"
                        ]
                    },
                    {
                        "index": 1,
                        "keyResponsibilities": [
                            "Designed and implemented RESTful APIs to serve data to the JavaScript front-end, handling over 500,000 concurrent users.",
                            "Developed an internal tool using NodeJS and Puppeteer.js to automate QA and monitor a donor-facing web app, improving CTR by 3%.",
                            "Reviewed code and conducted testing for 3 additional features, increasing contributions by 12%."
                        ],
                        "toolsTechnologies": [
                            "RESTful APIs",
                            "NodeJS",
                            "Puppeteer.js",
                            "JavaScript",
                            "SQL"
                        ]
                    },
                    {
                        "index": 2,
                        "keyResponsibilities": [
                            "Partnered with a developer to implement RESTful APIs in Django, increasing reporting speed for the analytics team by 24%.",
                            "Built a unit testing infrastructure using Selenium, reducing the number of bugs reported by the client by 11% month over month."
                        ],
                        "toolsTechnologies": [
                            "RESTful APIs",
                            "Django",
                            "Selenium",
                            "Python"
                        ]
                    }
                ],
                "toolsTechnicalSkills": "Expert in RESTful APIs, Django, Python, JavaScript, NodeJS, SQL. Proficient in HTML, CSS, Agile, Selenium, Tableau, Machine Learning, and Puppeteer.js.",
                "programmingLanguages": "Python, JavaScript, SQL, HTML, CSS, Django, NodeJS"
            }
        }
    };
    const resumeDetails = useSelector((state: any) => state.extractedData);

    /* const data = {
        summary: {
            "ATS Compatibility Score": "75%",
            "Keyword Metrics": {
                "Keyword Density": "3.5%",
                "Keyword Relevance": "8/10",
                "Top Keywords": [
                    "Power BI",
                    "Data Analysis",
                    "Dashboards",
                    "SQL",
                    "Reporting",
                    "Visualization",
                    "Data Fetching",
                    "Requirements Gathering",
                    "Automation",
                    "Process Improvement"
                ]
            },
            "Resume Structure and Format": {
                "Clear Headings": "90%",
                "Bullet Point Usage": "80%",
                "White Space Usage": "8/10"
            },
            "Content and Relevance": {
                "Work Experience Relevance": "9/10",
                "Skill Match": "85%",
                "Education Relevance": "7/10"
            },
            "Readability and Clarity": {
                "Readability Score": "65",
                "Sentence Complexity": "16 words",
                "Jargon and Acronyms": "15%"
            },
            "Actionable Tips and Suggestions": {
                "Improvement Suggestions": [
                    "Quantify achievements in bullet points with specific numbers and results.",
                    "Use action verbs to start bullet points.",
                    "Add more keywords related to the specific job description.",
                    "Tailor the resume to each specific job application."
                ],
                "Keyword Suggestions": [
                    "Data Modeling",
                    "Data Cleansing",
                    "Data Governance",
                    "Business Intelligence",
                    "Data Warehousing",
                    "DWH",
                    "ETL",
                    "Agile Methodology",
                    "Scrum",
                    "Project Management"
                ]
            }
        }
    }; */

    
    const {anchorEl, open, section, sectionValue, sectionIndex} = useSelector((state: any) => state.popover);
    const { sectionRefs } = useRefContext();
    const fixedSections = useSelector((state: any) => state.fixedSections);
    console.log("fixedSections", fixedSections);

    const resumeObjectWithChanges = atsCheckResult?.summary?.resumeObjectWithChanges;

    const generateChangeDescription = (section: string, index: number | null = null) => {
        if (section === 'careerObjective') {
            return 'Update your career objective.';
        } else if (section === 'toolsTechnicalSkills') {
            return 'Update your technical skills.';
        } else if (section === 'programmingLanguages') {
            return 'Update your programming languages.';
        } else if (section === 'workExperiences' && index !== null) {
            return `Update work experience at index ${index + 1}.`;
        } else if (section === 'projects' && index !== null) {
            return `Update project at index ${index + 1}.`;
        }
        return '';
    };

    const handleOpen = (event: React.MouseEvent<HTMLElement>, section: string, value: any, index: number) => {
        dispatch(openPopover(event.currentTarget, section, value, index));
    };

    const handleClose = () => {
        dispatch(closePopover());
    };

    const handleFix = () => {
        debugger
        if (sectionIndex !== -1) {
            dispatch(setExtractedData({
                ...resumeDetails,
                [section]: resumeDetails[section].map((item: any, index: number) => index === sectionIndex ? {...item, ...sectionValue} : item)
            }));
            dispatch(setFixedSections({
                ...fixedSections,
                [section]: {
                    ...(fixedSections[section] as { [index: number]: boolean }),
                    [sectionIndex]: true
                }
            }));
        } else {
            dispatch(setExtractedData({ ...resumeDetails, [section]: sectionValue }));
            dispatch(setFixedSections({ ...fixedSections, [section]: true }));
        }
        handleClose();
    };

    return (
      <Box>
        <Box className="app-bar-container">
          <AppBarHeader/>
        </Box>
        <Box sx={{display: "flex"}}>
            <Box className="side-drawer-container">
                <SideDrawer />
            </Box>
            <Grid container spacing={2} sx={{ background: "#FFF", m:0, overflowY: "auto", width: "100vw" }}>
                <Grid item xs={12} md={4} spacing={2} sx={{ p: 2 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                        <Box>
                            <Typography variant="h5" gutterBottom sx={{ fontWeight: "bolder",  }}>
                                ATS Checker
                            </Typography>
                            <Typography variant="body1" gutterBottom sx={{ color: 'text.secondary' }}>
                                Analyze your resume to ensure it passes Applicant Tracking Systems and improves your chances with recruiters
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: "column", gap: 2, px: 2, py: 1.5, border: "1px solid #FFDFA8", background: "#FFFBF4", borderRadius: 2 }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography sx={{ color: "#F59E0B", fontSize: 18, fontWeight: "bold"}}>Resume suggestions</Typography>
                                <BulbIcon />
                            </Box>
                            <Typography variant="body2">We reviewed your resume and suggest improving the following areas:</Typography>
                        </Box>
                        <Box>
                            <Card className={classes.resumeScorePanel}>
                                <Typography variant="h6" classes={classes.resumeScorePanelHeader} sx={{ p: 1.5, fontSize: 18, backgroundColor: `#F1F5F9` }}>
                                    ATS Compatibility Score
                                </Typography>
                                <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 3, py: 1.5, px: 3 }}>
                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                        <Box>
                                            <Typography variant="h5" sx={{ fontWeight: "bold", color: "#19A44C" }}>Good</Typography>
                                            <Typography variant="body2">Resume Strength</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="h4">70%</Typography>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <LinearProgress className={classes.progress} variant="determinate" value={70} />
                                    </Box>
                                    {/* <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <Typography variant="body2">See how the score is calculated?</Typography>
                                        <CustomButton variant="contained" color="success" disableRipple>Fix Resume</CustomButton>
                                    </Box> */}
                                </CardContent>
                            </Card>
                        </Box>
                        <Box>
                            <Card className={classes.resumeScorePanel}>
                                <Typography variant="h6" classes={classes.resumeScorePanelHeader} sx={{ p: 1.5, fontSize: 18, backgroundColor: `#F1F5F9` }}>
                                    Actionable Tips and Suggestions
                                </Typography>
                                <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 3, py: 1.5, px: 3 }}>
                                    <List>
                                        {Object.keys(resumeObjectWithChanges).map((section: string) => {
                                            if (Array.isArray(resumeObjectWithChanges[section])) {
                                                return resumeObjectWithChanges[section].map((item: any, index: number) => (
                                                    <ListItem key={`${section}-${index}`} className={classes.listItem}>
                                                        <ListItemText primary={generateChangeDescription(section, index)} classes={{ primary: classes.listItemText }} />
                                                        {!fixedSections[section]?.[index] ? (
                                                            <Button variant="contained" color="warning" disableRipple onClick={(event) => dispatch(openPopover(sectionRefs[`${section}-${index}`]?.current, section, item, index))}>Fix it</Button>
                                                        )
                                                        : "Fixed"}
                                                    </ListItem>
                                                ));
                                            } else {
                                                return (
                                                    <ListItem key={section} className={classes.listItem}>
                                                        <ListItemText primary={generateChangeDescription(section)} classes={{ primary: classes.listItemText }} />
                                                        {!fixedSections[section] ? 
                                                            (
                                                                <Button variant="contained" color="warning" disableRipple onClick={(event) => dispatch(openPopover(event.currentTarget, section, resumeObjectWithChanges[section]))}>Fix it</Button>
                                                            )
                                                        : "Fixed"}
                                                    </ListItem>
                                                );
                                            }
                                        })}
                                    </List>
                                </CardContent>
                            </Card>
                        </Box>
                        <List>
                        </List>
                        {/* <Box>
                            <Card className={classes.resumeScorePanel}>
                                <Typography variant="h6" classes={classes.resumeScorePanelHeader} sx={{ p: 1.5, fontSize: 18, backgroundColor: `#F1F5F9` }}>
                                    Metrics
                                </Typography>
                                <CardContent>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="subtitle1" gutterBottom>Resume Structure and Format</Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <Typography variant="body2" sx={{ width: '30%' }}>Clear Headings:</Typography>
                                            <LinearProgress variant="determinate" value={90} sx={{ width: '70%', ml: 2 }} />
                                            <Typography variant="body2" sx={{ ml: 2 }}>90%</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <Typography variant="body2" sx={{ width: '30%' }}>Bullet Point Usage:</Typography>
                                            <LinearProgress variant="determinate" value={80} sx={{ width: '70%', ml: 2 }} />
                                            <Typography variant="body2" sx={{ ml: 2 }}>80%</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <Typography variant="body2" sx={{ width: '30%' }}>White Space Usage:</Typography>
                                            <Typography variant="body2" sx={{ ml: 2 }}>8/10</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="subtitle1" gutterBottom>Content and Relevance</Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <Typography variant="body2" sx={{ width: '30%' }}>Work Experience Relevance:</Typography>
                                            <Typography variant="body2" sx={{ ml: 2 }}>9/10</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <Typography variant="body2" sx={{ width: '30%' }}>Skill Match:</Typography>
                                            <LinearProgress variant="determinate" value={85} sx={{ width: '70%', ml: 2 }} />
                                            <Typography variant="body2" sx={{ ml: 2 }}>85%</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <Typography variant="body2" sx={{ width: '30%' }}>Education Relevance:</Typography>
                                            <Typography variant="body2" sx={{ ml: 2 }}>7/10</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="subtitle1" gutterBottom>Readability and Clarity</Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <Typography variant="body2" sx={{ width: '30%' }}>Readability Score:</Typography>
                                            <LinearProgress variant="determinate" value={65} sx={{ width: '70%', ml: 2 }} />
                                            <Typography variant="body2" sx={{ ml: 2 }}>65</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <Typography variant="body2" sx={{ width: '30%' }}>Sentence Complexity:</Typography>
                                            <Typography variant="body2" sx={{ ml: 2 }}>16 words</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <Typography variant="body2" sx={{ width: '30%' }}>Jargon and Acronyms:</Typography>
                                            <Typography variant="body2" sx={{ ml: 2 }}>15%</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="body1">Keyword Suggestions:</Typography>
                                            
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box> */}
                    </Box>
                </Grid>
                <Grid item xs={12} md={8} sx={{ p: 2, background: "#5E646E", height: "100%" }}>
                    <Box sx={{ width: "100%", p: 6, pt: 1, boxSizing: "border-box", display: "flex", flexDirection: "column", gap: 2 }}>
                        <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography variant="h6" color={"#FFF"}>Resume Name.pdf</Typography>
                            <Button variant="contained" color="primary" disableRipple sx={{ fontSize: "16px" }} >Download</Button>
                        </Box>
                        <Box>
                            <Template1 height={"100%"} improvableSections={resumeObjectWithChanges} handleOpen={handleOpen}/>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
        {/* <Container id="features" sx={{ p: { xs: 4, sm: 2 }, pb: 10 }}>
          <Button variant="contained" color="primary" className={classes.backButton} onClick={() => navigate("/")}>
              Back
          </Button>
          <Typography variant="h4" gutterBottom>
              Resume ATS Check Results
          </Typography>
          <Box className={classes.root}>

              <Card className={classes.card}>
                  <CardContent>
                      <Typography variant="h6" className={classes.sectionTitle}>ATS Compatibility Score</Typography>
                      <LinearProgress variant="determinate" value={parseInt(data.summary["ATS Compatibility Score"])} className={classes.progress} />
                      <Typography variant="body1">{data.summary["ATS Compatibility Score"]}</Typography>
                  </CardContent>
              </Card>

              <Card className={classes.card}>
                  <CardContent>
                      <Typography variant="h5" className={classes.sectionTitle}>Keyword Metrics</Typography>
                      <Typography variant="body1">Keyword Density: {data.summary["Keyword Metrics"]["Keyword Density"]}</Typography>
                      <Typography variant="body1">Keyword Relevance: {data.summary["Keyword Metrics"]["Keyword Relevance"]}</Typography>
                      <Typography variant="body1">Top Keywords:</Typography>
                      <Box>
                          {data.summary["Keyword Metrics"]["Top Keywords"].map((keyword, index) => (
                              <Chip key={index} label={keyword} className={classes.chip} />
                          ))}
                      </Box>
                  </CardContent>
              </Card>

            <Card className={classes.card}>
                  <CardContent>
                      <Typography variant="h5" className={classes.sectionTitle}>Resume Structure and Format</Typography>
                      <Typography variant="body1">Clear Headings: {data.summary["Resume Structure and Format"]["Clear Headings"]}</Typography>
                      <Typography variant="body1">Bullet Point Usage: {data.summary["Resume Structure and Format"]["Bullet Point Usage"]}</Typography>
                      <Typography variant="body1">White Space Usage: {data.summary["Resume Structure and Format"]["White Space Usage"]}</Typography>
                  </CardContent>
              </Card>

              <Card className={classes.card}>
                  <CardContent>
                      <Typography variant="h5" className={classes.sectionTitle}>Content and Relevance</Typography>
                      <Typography variant="body1">Work Experience Relevance: {data.summary["Content and Relevance"]["Work Experience Relevance"]}</Typography>
                      <Typography variant="body1">Skill Match: {data.summary["Content and Relevance"]["Skill Match"]}</Typography>
                      <Typography variant="body1">Education Relevance: {data.summary["Content and Relevance"]["Education Relevance"]}</Typography>
                  </CardContent>
              </Card>

              <Card className={classes.card}>
                  <CardContent>
                      <Typography variant="h5" className={classes.sectionTitle}>Readability and Clarity</Typography>
                      <Typography variant="body1">Readability Score: {data.summary["Readability and Clarity"]["Readability Score"]}</Typography>
                      <Typography variant="body1">Sentence Complexity: {data.summary["Readability and Clarity"]["Sentence Complexity"]}</Typography>
                      <Typography variant="body1">Jargon and Acronyms: {data.summary["Readability and Clarity"]["Jargon and Acronyms"]}</Typography>
                  </CardContent>
              </Card>

              <Card className={classes.card}>
                  <CardContent>
                      <Typography variant="h5" className={classes.sectionTitle}>Actionable Tips and Suggestions</Typography>
                      <Typography variant="body1">Improvement Suggestions:</Typography>
                      <List>
                          {data.summary["Actionable Tips and Suggestions"]["Improvement Suggestions"].map((tip, index) => (
                              <ListItem key={index} className={classes.listItem}>
                                  <ListItemText primary={tip} classes={{ primary: classes.listItemText }} />
                              </ListItem>
                          ))}
                      </List>
                      <Typography variant="body1">Keyword Suggestions:</Typography>
                      <Box>
                          {data.summary["Actionable Tips and Suggestions"]["Keyword Suggestions"].map((keyword, index) => (
                              <Chip key={index} label={keyword} className={classes.chip} />
                          ))}
                      </Box>
                  </CardContent>
              </Card>
          </Box>
        </Container> */}

        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
        >
            <DialogTitle>Edit Section</DialogTitle>
            <DialogContent sx={{ width: "500px" }}>
            <DialogContentText>
                {
                    section === 'workExperiences' && sectionIndex !== null ? 
                        sectionValue.keyResponsibilities.map((item: string, index: number) => (
                            <TextField
                                onFocus={(event) => {
                                    // debugger;
                                }}
                                key={index}
                                variant="outlined"
                                fullWidth
                                multiline
                                value={item}
                                sx={{
                                    my:1
                                }}
                                onChange={(event) => {
                                    const updatedKeyResponsibilities = sectionValue.keyResponsibilities.map((val: string, i: number) => (i === index ? event.target.value : val));
                                    const updatedValue = {
                                        ...resumeDetails.workExperiences[index],
                                        keyResponsibilities: updatedKeyResponsibilities
                                    };
                                    dispatch(updatePopoverValue(updatedValue));
                                }}
                            />
                        ))
                    :
                    section === "toolsTechnicalSkills" || section === "programmingLanguages" || section === "certifications" ?
                        typeof sectionValue !== "string" && sectionValue.map((item: string, index: number) => (
                            <Chip key={index} label={item} className={classes.chip} onDelete={(event) => {
                                const updatedSectionValue = sectionValue.filter((val: string, i: number) => (i !== index));
                                dispatch(updatePopoverValue(updatedSectionValue));
                            }}/>
                        ))
                        :
                    Array.isArray(sectionValue) ? sectionValue.map((item: string, index: number) => (
                        <TextField
                            key={index}
                            variant="outlined"
                            fullWidth
                            sx={{
                                my: 1
                            }}
                            value={item}
                            onChange={(event) => {
                                const updatedValue = sectionValue.map((val: string, i: number) => (i === index ? event.target.value : val));
                                dispatch(updatePopoverValue(updatedValue));
                            }}
                        />
                    )) :
                    <TextField
                        variant="outlined"
                        fullWidth
                        multiline
                        sx={{
                            my: 1
                        }}
                        value={sectionValue}
                        onChange={(event) => {
                            const updatedValue = event.target.value;
                            dispatch(updatePopoverValue(updatedValue));
                        }}
                    />
                }
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="secondary">Cancel</Button>
            <Button onClick={handleFix} color="primary">Change</Button>
            </DialogActions>
        </Popover>
      </Box>
    );
};

export default ATSCheckResult;