import { Box, Button, InputLabel, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {ReactComponent as DatePickerIcon} from "../../../../assets/SVG/date-picker.svg";
import { SetStateAction, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setExtractedData } from "../../../../redux/actions";
import { CourseType, IResume } from "../../../../components/utils";
import { Dispatch } from "react";
import { StateProps } from "../../../../redux/reducer";
import EducationCard from "../../EducationCard";
import { isValidUrl } from "../../../../components/common/CommonFunctions";

  interface CourseProps {
    courseData:CourseType;
    coursesList:CourseType[];
    setCoursesList:Dispatch<SetStateAction<CourseType[]>>
  }

const CourseEdit:React.FC<CourseProps> = ({courseData,coursesList,setCoursesList}) => {

    const dispatch = useDispatch();
    const extractedData = useSelector((state:StateProps) => state.extractedData);
    const [addLink,setAddLink] = useState<boolean>(false);
    //formData handles local form state
    const [formData, setFormData] = useState<CourseType>(courseData);
    const [errors,setErrors] = useState({
        organization:"",
        startDate:"",
        endDate:"",
        url:""  
      });
    const handleChange = (newValue: any, key: string) => {
        if(key === "courseDescription") {
            const wordLimit = 50
            const wordCount = newValue.trim().split(/\s+/).filter(Boolean).length;
            if(wordCount<=wordLimit){
                setFormData({
                    ...formData,
                    [key]: newValue,
                  });
            }
        }
        else {
          //If already showing error for the field to clear that
            setErrors((prev)=> ({
              ...prev,
              [key]:"",
            }))
            setFormData({
                ...formData,
                [key]: newValue,
              });
        }
      };
    
      const handleStartDateChange = (newValue: Date | null) => {
        if(newValue === null) return;
        const date = new Date(newValue);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        
        const formattedDate = `${year}-${month}-${day}`;
        handleChange(formattedDate, 'startDate');
        if (newValue && formData.endDate !== null && newValue > (formData.endDate as any)) {
          handleChange(null, 'endDate');
        }
      };
    
      const handleEndDateChange = (newValue: Date | null) => {
        if(newValue === null) return;
        const date = new Date(newValue);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        
        const formattedDate = `${year}-${month}-${day}`;
        handleChange(formattedDate, 'endDate');
      };

      const handleCancel = () => {
        if(!!formData?.isNewEntry) {
            setCoursesList((prev) => prev ? prev.slice(0, -1) : prev)
        }
        else {
            setCoursesList((prev)=> prev.map((courEle) => {
                if(courEle?.organization === courseData.organization 
                    && courEle?.startDate === courseData?.startDate 
                    && courEle?.endDate === courseData?.endDate) {
                    return {...courEle, isEdit: false};
                }
                return courEle;
            }));
        }
      }
  
      const handleSave = () => {
        if(!formData?.organization) {
          setErrors((prev)=>({
            ...prev,
            organization:"Organization is required"
          }))
        }
        if(!formData?.startDate){
          setErrors((prev)=>({
            ...prev,
            startDate:"Start Date is required"
          }))
        }
        if(!formData?.endDate) {
          setErrors((prev)=>({
            ...prev,
            endDate:"End Date is required"
          }))
        }
        if(!isValidUrl(addLink,formData?.link ?? "")) {
            setErrors((prev)=>({
                ...prev,
                url:"Invalid URL"
              }))
          }
        if(formData?.organization && formData?.startDate && formData?.endDate) {
            //checking if link is present then It must contain the valid url
          if(!isValidUrl(addLink,formData?.link ?? "")) {
            setErrors((prev)=>({
                ...prev,
                url:"Invalid URL"
              }))
          }
          else {
            //perform new course entry
            if(formData?.isNewEntry) {
                /**
                 * New Entry
                */
               delete formData?.isNewEntry;
               delete formData?.index;
                dispatch(setExtractedData({
                  ...extractedData,
                  courses: [
                    ...extractedData.courses ?? [],
                     {...formData,isEdit:false}
                  ],
                }));
              }
              else {
                const updatedCourses = coursesList?.map((course)=> {
                    if(course?.index === formData?.index) {
                      delete formData?.index;
                        return {...formData,isEdit:false};
                    }
                    delete course?.index;
                    return course;
                })
                dispatch(setExtractedData({
                  ...extractedData,
                  courses:updatedCourses,
                }));
              }
              //both cases remove error
              setErrors({
                organization:"",
                startDate:"",
                endDate:"", 
                url:"",
              })
          }
        }
      };
           
   return (
    <Box className="section-item-container">
      <Box className="courses-edit-section common-edit-section">
    <Box className="input-box-container">
    <InputLabel htmlFor="company">Course title*</InputLabel>
    <TextField
        fullWidth
        id="company"
        name="company"
        placeholder="Ex: Microsoft Corporation"
      value={formData?.organization}
      onChange={(e) => handleChange(e.target.value, 'organization')}
      error={!!errors.organization}
      helperText={errors.organization ? errors.organization : ''}
    />
    </Box>

    <Box className="courses-dates-container">
    <Box className="input-box-container">
        <InputLabel htmlFor="startDate">
            Start Date
        </InputLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
        value={formData?.startDate as any}
        onChange={handleStartDateChange}
        slots={{
            openPickerIcon: DatePickerIcon,
            textField: (params) => (
                <TextField
                {...params}
                fullWidth
                id="startDate"
                name="startDate"
                value={formData?.startDate}
                error={!!errors.startDate}
                helperText={errors.startDate ? errors.startDate : ""}
                />
            ),
            }}
            />
    </LocalizationProvider>
    </Box>

    <Box className="input-box-container">
        <InputLabel htmlFor="endDate">
                End Date
        </InputLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
            value={formData?.endDate as any}
            onChange={handleEndDateChange}
            minDate={formData?.startDate ? formData?.startDate as any : undefined}
            slots={{
            openPickerIcon: DatePickerIcon,
            textField: (params) => (
                <TextField
                {...params}
                fullWidth
                id="endDate"
                name="endDate"
                value={formData?.endDate}
                error={!!errors?.endDate}
                helperText={errors?.endDate ? errors.endDate : ""}
                />
            ),
            }}
            /> 
        </LocalizationProvider>
    </Box>
    </Box>

    <Box className="input-box-container">
    <InputLabel htmlFor="address">
    Description
    </InputLabel>
    <TextField
    multiline
    rows={2}
    fullWidth
    value={formData?.courseDescription}
    id="address"
    placeholder="Ex: +91 123456789"
    onChange={(e) => handleChange(e.target.value,"courseDescription")}
    />
     <Typography
        variant="body2"
        className="word-count-text"
      >
        {`${formData?.courseDescription?.trim().split(/\s+/).filter(Boolean).length}/50 words`}
      </Typography>
   </Box>

 {addLink &&   <Box className="input-box-container">
    <Box sx={{display:"flex",justifyContent:"space-between"}}>
    <InputLabel htmlFor="link">Link*</InputLabel>
    <Typography className="delete-link-section" onClick={()=>setAddLink(false)}>Delete</Typography>
    </Box>
    <TextField
        fullWidth
        id="link"
        name="link"
        placeholder="Ex. www.google.com"
      value={formData?.link}
      onChange={(e) => handleChange(e.target.value, 'link')}
      error={!!errors.url}
      helperText={errors.url ? errors.url : ''}
    />
    </Box>}

    <Box className="section-action">
        <Box className="link-container">
        {!addLink && <Typography className="add-link-text" onClick={()=>setAddLink(true)}>Add Link</Typography>}
        </Box>
        <Box className="button-container">
        <Button className="cancel-button" variant="outlined" size="medium" onClick={handleCancel}>Cancel</Button>
        <Button className="add-button" variant="contained" size="medium" onClick={handleSave}>Add</Button>
        </Box>
    </Box>
    </Box>
    </Box>

   )
}

export default CourseEdit;