import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CourseType, EducationTypes, WorkExperienceTypes } from "../../../components/utils";
import { useDispatch, useSelector } from "react-redux";
import { StateProps } from "../../../redux/reducer";
import { setExtractedData } from "../../../redux/actions";
import EducationCard from "../EducationCard";
import SectionEntryModal from "../SummarySections/SectionEntryModal";

interface CourseCardProps {
    courseData:WorkExperienceTypes;
    coursesList:WorkExperienceTypes[];
    setCoursesList:Dispatch<SetStateAction<WorkExperienceTypes[]>>
  }

export interface ISectionEntryModalData {
  modalState:boolean;
  index:number;
  entryDelete:boolean;
}


const WorkExperienceCard:React.FC<CourseCardProps> = ({courseData,coursesList,setCoursesList}) => {
  const [sectionEntryModal,setSectionEntryModal] = useState<ISectionEntryModalData>({modalState:false,index:0, entryDelete:false});
    const dispatch = useDispatch();
    const extractedData = useSelector((state:StateProps) => state.extractedData);

    useEffect(()=>{
      if(sectionEntryModal?.modalState && sectionEntryModal?.entryDelete){
        const updatedCourses = coursesList?.filter(course=> course?.index !== sectionEntryModal?.index)?.map((course) => {
          delete course.index;
          return course;
         })
         dispatch(setExtractedData({
           ...extractedData,
           workExperiences:updatedCourses,
         }));
      }
      setSectionEntryModal({modalState:false,index:0, entryDelete:false})
    },[sectionEntryModal?.entryDelete])

    const handleEdit = (index:number|undefined) => {
        setCoursesList((prev) => prev?.map((course) => {
         if(index===course?.index) {
           return {...course,isEdit:true}
         }
         return course;
       }))
     }

     const handleDelete = (index:number|undefined) => {
      setSectionEntryModal((prev)=> ({...prev,modalState:true,index:index ?? 0}))
     }

  return (
    <>
        <EducationCard details={{heading:courseData?.role,
        startDate:courseData?.startDate ,
        endDate:courseData?.endDate,
         company:courseData?.company,
         aboutText:courseData?.keyResponsibilities,
        index:courseData?.index
    }}
        handleEditClick={()=>handleEdit(courseData?.index)}
        handleDeleteClick={()=>handleDelete(courseData?.index)}
        />
      <SectionEntryModal sectionEntryModal={sectionEntryModal} setSectionEntryModal={setSectionEntryModal}/>
    </>
  )
}
export default WorkExperienceCard;