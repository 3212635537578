import { AddTask, ContentCopy, DoneAll, Work } from "@mui/icons-material";
import { Box, Divider, IconButton, List, ListItem, ListItemButton, ListItemSecondaryAction, Popover, Typography } from "@mui/material";
import { checkMissingKeywords } from "../components/utils";
import { useSelector } from "react-redux";
import { useMemo, useState } from "react";

const ImproveResumeBtn = (props: any) => {
  const { section, value } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  let extractedData = useSelector((state: any) => state.extractedData);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getMissingKeywords = useMemo(() => {
    if(!extractedData.tailoredResumeObject || section === "" || value === "") return [];
    const missingKeywords = checkMissingKeywords(value, section, extractedData.tailoredResumeObject.keywordsToAdd);
    return missingKeywords;
  }, [extractedData, section, value]);

  const handleCopy = (keyword: string) => {
    navigator.clipboard.writeText(keyword);
  };

  const open = Boolean(anchorEl);

  return ((extractedData.tailoredResumeObject && Object.keys(extractedData.tailoredResumeObject).length > 0) || getMissingKeywords.length === extractedData.tailoredResumeObject?.keywordsToAdd?.length) && (
    <Box>
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 24,
          height: 24,
          marginLeft: 2,
          backgroundColor: '#0088ff',
          borderRadius: "20%",
          cursor: 'pointer'
        }}
        onClick={handleClick}
      >
        <AddTask fontSize="inherit" sx={{ color: "#FFF" }} />
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 1, textAlign: "center" }}>
          <Typography variant="body1" sx={{ pb: 0.6, display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 24,
                height: 24,
                mr: 0.5,
                backgroundColor: '#0088ff',
                borderRadius: "20%",
                cursor: 'pointer'
              }}
              onClick={handleClick}
            >
              <Work fontSize="inherit" sx={{ color: "#FFF" }} />
            </Box>
            {extractedData.tailoredResumeObject.designation}
          </Typography>
          <Typography variant="body2">
            {extractedData.tailoredResumeObject.keywordsToAdd.length - getMissingKeywords.length}/{extractedData.tailoredResumeObject.keywordsToAdd.length} Keyword
          </Typography>
          <Divider sx={{ py: 0.5 }} />
          <List sx={{
            height: "250px",
            width: "200px",
            overflow: "auto",
            '&::-webkit-scrollbar': {
              width: '1px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '2px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555',
            },
            scrollbarWidth: 'thin',
            scrollbarColor: '#888 #f1f1f1'
          }}>
            {extractedData.tailoredResumeObject.keywordsToAdd?.length > 0 ? (
              extractedData.tailoredResumeObject.keywordsToAdd.map((keyword: string, index: number) => (
                <ListItem key={index} sx={{p: 0}}>
                  <ListItemButton sx={{px: 0}}>
                    <Typography variant="body2">{keyword}</Typography>
                    <ListItemSecondaryAction>
                      {getMissingKeywords.includes(keyword) ? (
                        <IconButton onClick={() => handleCopy(keyword)} size="small">
                          <ContentCopy sx={{ fontSize: "14px" }} />
                        </IconButton>
                      ) : (
                        <DoneAll fontSize="small" sx={{color: "green"}} />
                      )}
                    </ListItemSecondaryAction>
                  </ListItemButton>
                </ListItem>
              ))
            ) : (
              <Typography variant="body2">No missing keywords</Typography>
            )}
          </List>
        </Box>
      </Popover>
    </Box>
  );
}

export default ImproveResumeBtn;