import { AddTask, AutoFixHigh } from "@mui/icons-material";
import { Box, Button, Popover, Typography } from "@mui/material";
import { id } from "date-fns/locale";
import { useState } from "react";
import { useSelector } from "react-redux";

const GetHelpWithWriting = (props: any) => {

  const extractedData = useSelector((state:any) => state.extractedData);

  const { section, value, inputRef } = props;
  
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const simulateTyping = (quillEditor: any, text: string) => {
    let index = 0;
    const interval = setInterval(() => {
      if (index < text.length) {
        quillEditor.insertText(index, text[index]);
        index++;
      } else {
        clearInterval(interval);
      }
    }, 50); // Adjust the typing speed by changing the interval time
  };

  const generateContent = async () => {
    const response = await fetch('https://be.tekno.ai/get-help-with-writing', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sectionToGenerate: section,
        resumeObject: extractedData,
      }),
    });
  
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
  
    const result = await response.json();
    debugger;
    if (inputRef.current && section === "careerObjective") {
      const quillEditor = inputRef.current.getEditor();
      simulateTyping(quillEditor, `${result.response}`);
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: 2,
          backgroundColor: '#0088ff',
          cursor: 'pointer',
          color: "#FFF",
          px: 1,
          py: 0.5,
        }}
        onClick={handleClick}
      >
        <AutoFixHigh fontSize="inherit" /> <Typography variant="caption">Get Help with Writing</Typography>
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2, maxWidth: 300 }}>
          <Typography variant="h6" gutterBottom>AI Writer</Typography>
          <Typography variant="body2" gutterBottom>
            The AI uses your work experience, education and skills to optimize your profile for the job you want.
          </Typography>
          <Button variant="contained" color="primary" onClick={generateContent}>Generate</Button>
        </Box>
      </Popover>
    </Box>
  )
}

export default GetHelpWithWriting;