import React, { Suspense, useEffect, useLayoutEffect, useRef, useState, useTransition } from 'react';
import Box  from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { StateProps } from '../redux/reducer';
import { useMediaQuery } from '@mui/material';
import { createTheme } from '@mui/system';

const ProcessResumePreview = () => {
    const templateId = useSelector((state: any) => state.templateId);
    const previewResumeData = useSelector((state:StateProps)=> state?.previewResumeData);
    
    const [TemplateComponent, setTemplateComponent] = useState<React.LazyExoticComponent<React.ComponentType<any>> | null>(null);
    const [isPending, startTransition] = useTransition();
    const templateContainer = useRef<HTMLDivElement>(null);


    useEffect(() => {
        startTransition(() => {
            const importTemplate = async () => {
                try {
                    const templateModule = await import(`../templates/Template${templateId || '1'}`);
                    if (templateModule.default) { // Ensure there's a default export
                        setTemplateComponent(() => templateModule.default);
                    } else {
                        console.error("Template component does not have a default export");
                        setTemplateComponent(null);
                    }
                } catch (error) {
                    console.error("Failed to load the template component", error);
                    setTemplateComponent(null);
                }
            };

            importTemplate();
        });
    }, [templateId]);

    useLayoutEffect(()=>{
        if(templateContainer.current && previewResumeData?.resumeHeight && previewResumeData?.multiplicationFactor) {
            console.log(previewResumeData,"from effect if")
            templateContainer.current.style.height = `${previewResumeData?.resumeHeight  + 48}px`; //* previewResumeData?.multiplicationFactor   
        }
    },[previewResumeData?.resumeHeight, previewResumeData?.multiplicationFactor])

    return (
        <Box className="preview-template-container" ref={templateContainer} >
            {/* ref={templateContainer} */}
            <Suspense fallback={<div>Loading template...</div>}>
                <Box className="preview-template"
                sx={{margin:{
                    xs:"1rem 0rem 0rem 0rem",
                    sm:"1rem 0rem 0rem 0rem",
                    md:"0.3rem 0rem 0rem 0rem",
                    lg:"0.3rem 0rem 0rem 0rem",
                    xl:"0.5rem 15rem 0 15rem",
                },
                transform:{
                    md:"scale(0.6,0.6)",
                    lg:"scale(0.6,0.6)",
                    xl:"scale(0.75,0.75)",
                }
            }}
                >
                    {isPending ? <div>Loading new template...</div> : TemplateComponent ? <TemplateComponent /> : <div>Template not found</div>}
                </Box>
            </Suspense>
        </Box>
    )
}

export default ProcessResumePreview;