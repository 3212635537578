import { Box, ListItem, Switch, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateProps } from "../../../redux/reducer";
import { EducationTypes } from "../../../components/utils";
import { setExtractedData } from "../../../redux/actions";
import EducationEdit from "./EducationEdit";
import EducationCardWrapper from "./EducationCardWrapper";
import { styled } from '@mui/material/styles';

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#1890ff',
          ...theme.applyStyles('dark', {
            backgroundColor: '#177ddc',
          }),
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
      ...theme.applyStyles('dark', {
        backgroundColor: 'rgba(255,255,255,.35)',
      }),
    },
  }));

interface CoursesListProps {
  handleSectionDelete:(section:string) =>void;
  sectionName:string;
}

const DEFAULT_FORM_DATA:EducationTypes = {
  universityCollege: "",
  startDate:null,
  endDate:null,
  degree:"",
  location:"",
  specialization:"",
  isEdit:true, 
  isNewEntry:true,  
};


const EducationList:React.FC = () => {

  const extractedData = useSelector((state:StateProps) => state.extractedData);
  const dispatch = useDispatch();
  const [coursesList,setCoursesList] = useState<EducationTypes[]>(extractedData?.educationalDetails ?? []);
  const [draggingItemIndex, setDraggingItemIndex] = useState<number|null>(null);


  //Adding key to extrated course elements
  useEffect(()=>{
    if(!extractedData?.educationalDetails?.[0]) {
      setCoursesList([{...DEFAULT_FORM_DATA,index:0}])
    }
    else {
      const updatedList = extractedData?.educationalDetails?.map((course,i) =>{
        return {...course,isEdit:false,index:i}
      });
      setCoursesList(updatedList ?? []);
    }
  },[extractedData?.educationalDetails]);

  const handleAddCourse = () => {
    //Adding new course entry as form at the end
    setCoursesList([...coursesList,{...DEFAULT_FORM_DATA,index:coursesList?.length ?? 1}])
  }

  const handleDragStart = (index:number) => {
    setDraggingItemIndex(index);
  }

  const handleDragOver = (e:any,index:number) => {
    e.preventDefault();
  }

const handleDrop = (index:number) => {
  const draggedOverItem = extractedData?.educationalDetails?.[index];

  // If the item is dragged over itself, don't change the list
  if (draggingItemIndex === index) {
    return;
  }

  if(draggedOverItem && (draggingItemIndex || draggingItemIndex===0) && extractedData?.educationalDetails?.[draggingItemIndex]){
    const updatedItems = [...extractedData?.educationalDetails];

    const temp = updatedItems[index];
    updatedItems[index] = updatedItems[draggingItemIndex];
    updatedItems[draggingItemIndex] = temp;

          dispatch(setExtractedData({
            ...extractedData,
            educationalDetails:updatedItems,
          }));
      setDraggingItemIndex(index);
      }
    };


const handleDragEnd = () => {
  setDraggingItemIndex(null);
}


   return (
    <Box className="summary-container">
             <Box className="courses-section">
            <Box className="heading-box">
            <Typography variant="h5" color="color.primary" className="heading">
              Education
            </Typography>
           <Box sx={{display:"flex",justifyContent:"left",alignItems:"center",gap:"0.5rem"}}>
              <Typography sx={{fontSize:"14px",color:"#344054"}}>Have Perior Education?</Typography>
              {/* <Switch defaultChecked /> */}
              <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
           </Box>
    </Box>

    {coursesList?.map((course,index)=>(<ListItem className="card-wrapper" 
     draggable={course?.isEdit ? false :true}
     onDragStart={()=>handleDragStart(index)}
     onDragOver={(e)=>handleDragOver(e,index)}
     onDrop={()=>handleDrop(index)}
     onDragEnd={handleDragEnd}
     sx={draggingItemIndex === index ? {
      transition:"transform 0.3s ease",
      opacity: "0.7",
      transform: "scale(1.05)",
      cursor: course?.isEdit ? "none" : "grab",
     } : {}}
     >
      {course?.isEdit ? <EducationEdit courseData={course}
       coursesList={coursesList} 
       setCoursesList={setCoursesList}
       />
       : <EducationCardWrapper courseData={course}
        coursesList={coursesList}
         setCoursesList={setCoursesList}
         />}
      </ListItem>))}

    {!(coursesList?.[coursesList?.length - 1]?.isNewEntry) && <Box className="add-course-button-cont">
     <Typography className="summary-ui-brand" onClick={handleAddCourse}>Add Education</Typography>
    </Box>}
    
    </Box>
    </Box>
   )
}

export default EducationList;