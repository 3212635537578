import { SocialType } from "./SocialLinks";

export const isValueExist = (field:string):boolean => {
    if(field && field!=="NA") {
      return true;
    }
    return false;
  }

// Function to get valid key-value pairs as an array of objects in the format { key, value }
export const getValidKeyValuePairs = (obj: Record<keyof SocialType, string | null>): { key: keyof SocialType, value: string }[] => {
  return Object.keys(obj)
    .filter(key => {
      const value = obj[key as keyof SocialType];
      return value && typeof value === 'string' && value !== 'NA'; // Only keep valid string values
    })
    ?.map(key => ({ key:key as keyof SocialType, value: obj[key as keyof SocialType] || "" })); // Return each valid key-value as an object
};

//Common utils to return error for link input
export const isValidUrl = (addLink:boolean,value:string) => {
  if(!addLink) return true;
  if(addLink){
    if(!value) return false;
    const urlRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/
    return urlRegex.test(value);
  };

}