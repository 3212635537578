import './App.css';
import "./styles/style.scss";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import UserLandingPage from './UserLandingPage';
import ProcessResumeFeature from './ProcessResume/ProcessResumeFeature';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TemplatesList from './components/templates/TemplatesList';
import { Login, Register } from './components/auth';
import { Provider, useSelector } from 'react-redux';
import { createStore } from 'redux';
import reducer from './redux/reducer';
import LandingPage from './LandingPage/LandingPage';
import CreateAccount from './components/Accounts/CreateAccount';
import EditAccount from './components/Accounts/EditAccount';
import ForgotPassword from './components/Accounts/ForgotPassword';
import ATSCheckResult from './components/ATSChecker/ATSCheckResult';
import { ILoggedinUserData } from './components/utils';
import { getToken } from './components/auth/storeToken';
import CssBaseline from '@mui/material/CssBaseline';
import { Padding } from '@mui/icons-material';
import SavedResumes from './components/Accounts/SavedResume';
import { RefProvider } from './templates/TemplateSectionsRef';

const theme = createTheme({
  typography: {
    fontFamily: [
      'TT Commons',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // below padding eefects the padding of all text fields
          padding:"0.6rem",
          borderRadius: '10px',
          backgroundColor: "#fff",
          '& fieldset': {
            borderRadius: '10px',
            border: '1px solid lightgray',
          },
        },
        input: {
          padding: '0rem',
        },
        inputMultiline: {
          padding: '0rem', // Reduced padding for multiline text fields
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        inputMultiline: {
          padding: '0px', // This is the crucial part to ensure no padding
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          backgroundColor: "#fff",
          color: "#344054",
          padding: "0rem",
          '& fieldset': {
            borderColor: 'lightgrey',
            borderRadius: '10px',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: '0.1rem 0.5rem',
          borderRadius: '10px',
        },
        input: {
          padding: '0px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#667085', // Change label color
          fontSize: '14px', // Customize font size
          fontWeight:500,
          '&.Mui-focused': {
            color: 'blue', // Change color when focused
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: '8px',
          padding: '4px 18px', 
          textTransform: 'none', 
        },
        contained: {
          backgroundColor: '#5598ff',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#1565c0',
          },
        },
        outlined: {
          borderColor: '#1976d2',
          color: '#344054',
          '&:hover': {
            borderColor: '#1565c0', 
            color: '#1565c0', 
          },
        },
        text: {
          color: '#1976d2', 
          fontSize:"16px",
          '&:hover': {
            backgroundColor: 'rgba(25, 118, 210, 0.1)',
          },
        },
      },
    },
  },
});



function AuthRoute({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const accessToken = useSelector((state:any) => state.accessToken);
  const extractedData = useSelector((state:any) => state.extractedData);
  const loggedInUserData: ILoggedinUserData = useSelector((state: any) => state.loggedInUserData);

  const isAuthenticated = accessToken || (loggedInUserData && Object.keys(loggedInUserData).length > 0) || getToken();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  
  return children;
}


function App() {

const store = createStore(reducer);

  return (
    <Provider store={store}>
      <RefProvider>
        <CssBaseline/>
        <ThemeProvider theme={theme}>
          <main>
            <div className='app-page-container'>
              <Router>
              <Routes>
                <Route path="landing-page" element={<LandingPage />} />
                <Route path="/" element={<AuthRoute><UserLandingPage /></AuthRoute>} />
                <Route path="login" element={<Login />} />
                <Route path="create-account" element={<CreateAccount />} />
                <Route path="register" element={<Register />} />
                <Route path="edit-account" element={<EditAccount />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="my-resumes" element={<AuthRoute><SavedResumes /></AuthRoute>} />
                <Route path="select-template" element={<AuthRoute><TemplatesList/></AuthRoute>}/>
                  <Route path="ats-check-result" element={<AuthRoute><ATSCheckResult/></AuthRoute>}/>
                  <Route path="process-resume/*" element={<AuthRoute><ProcessResumeFeature/></AuthRoute>}/>
              </Routes>
              </Router>
            </div>
          </main>
        </ThemeProvider>
      </RefProvider>
    </Provider>
  );
}

export default App;