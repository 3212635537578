import React from 'react';
import styles from "./Template5.module.scss";
import { isValueExist } from '../components/utils';
import { useSelector } from 'react-redux';

const Template5 = (props:any) => {
 const resumeDetails = useSelector((state:any) => state.extractedData);

 return (
  <div className={styles["template5-container"]}>
    <div className={styles["profile-picture-container"]}><div className={styles["profile-picture"]}><img style={{width:"150px", height:"150px"}} src={resumeDetails?.profile_Picture ? resumeDetails?.profile_Picture : "./profile-picture.jpg"} alt="Profile" /></div> </div>
    <div className={styles.header}>
      <h1>{resumeDetails?.name?.firstName} {resumeDetails?.name?.lastName}</h1>
      <h2>{resumeDetails?.designation}</h2>
    </div>

    <div className={styles["contact-info"]}>
      <p>Email: {resumeDetails?.email}</p>
      <p>|</p>
      <p>Phone: {resumeDetails?.phone?.join(", ")}</p>
    </div>

    <div className={styles["main-content"]}>
    {isValueExist(resumeDetails?.careerObjective) &&  <div id="career_objective" className={styles["career-objective-section"]}>
      <h2>Career Objective: </h2>
          <div>
            {resumeDetails?.careerObjective}
          </div>
        </div>}

      <div className={styles["skills-section"]}>
        <h2>Skills</h2>
        {typeof resumeDetails?.toolsTechnicalSkills === "string" ? <div className={styles["skill"]}>{resumeDetails?.toolsTechnicalSkills}</div> : resumeDetails?.toolsTechnicalSkills.map((skill: string, index: number) => (
          <div className={styles["skill"]} key={index}>{skill}</div>
        ))}
      </div>

      <div className={styles["experience-section"]}>
        <h2>Experience</h2>
        {resumeDetails?.workExperiences && resumeDetails?.workExperiences.map((workExperience: any, index: number) => (
          <div className={styles["experience"]} key={index}>
            <h3>{workExperience.role} at {workExperience.company}</h3>
            <p>{workExperience.startDate} - {workExperience.endDate}</p>
            <ul>
              {workExperience.keyResponsibilities.split('\n').map((resp: string, i: number) => (
                <li key={i}>{resp}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div className={styles["education-section"]}>
        <h2>Education</h2>
        {resumeDetails?.educationalDetails && resumeDetails?.educationalDetails.map((education: any, index: number) => (
          <div className={styles.education} key={index}>
            <h3>{education.degree} in {education.specialization}</h3>
            <p>{education.universityCollege}</p>
            <p>GPA {education.gpa}</p>
            <p>{education.startDate} - {education.endDate}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
 )

};

export default Template5;