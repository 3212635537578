import React from 'react';
import styles from './Template4.module.css';
import { useSelector } from 'react-redux';

interface ResumePreviewProps {
  resumeDetails: any;
  wrapperRef: any;
  selectedScreen: any;
}

// Create Document Component
const Template4: React.FC = (props: any) => {
  const resumeDetails = useSelector((state:any) => state.extractedData);

  return (
    <div className={styles.modernResume}>
      <div className={styles.header}>
        <h1 className={styles.headerH1}>{resumeDetails?.name?.firstName} {resumeDetails?.name?.lastName}</h1>
        <h2 className={styles.headerH2}>{resumeDetails.designation}</h2>
      </div>

      <div className={styles.contactInfo}>
        <p>Email: {resumeDetails.email}</p>
        <p>Phone: {resumeDetails.phone?.join(", ")}</p>
      </div>

      <div className={styles.mainContent}>
        <div className={styles.skillsSection}>
          <h2 className={styles.sectionH2}>Skills:</h2>
          {typeof resumeDetails.toolsTechnicalSkills === "string" ? 
            <p className={styles.skill}>{resumeDetails.toolsTechnicalSkills}</p> : 
            resumeDetails.toolsTechnicalSkills?.map((skill: string, index: number) => (
              <p className={styles.skill} key={index}>{skill}</p>
            ))
          }
        </div>

        <div className={styles.experienceSection}>
          <h2 className={styles.sectionH2}>Experience: </h2>
          <div className={styles.experienceWrapper}>
          {resumeDetails.workExperiences && resumeDetails.workExperiences.map((workExperience: any, index: number) => (
            <div className={styles.experience} key={index}>
              <h3 className={styles.sectionH3}>{workExperience.role} at {workExperience.company}</h3>
              <p className={styles.sectionDate}>{workExperience.startDate} - {workExperience.endDate}</p>
              {workExperience.keyResponsibilities && workExperience.keyResponsibilities.split('\n').map((resp:string, i: number) => (
                <p className={styles.sectionLi} key={i}>{resp}</p>
              ))}
            </div>
          ))}
          </div>
        </div>

        <div className={styles.educationSection}>
          <h2 className={styles.sectionH2}>Education: </h2>
          <div className={styles.educationWrapper}>
          {resumeDetails.educationalDetails && resumeDetails.educationalDetails.map((education: any, index: number) => (
            <div className={styles.education} key={index}>
              <h3 className={styles.sectionH3}>{education.Degree} in {education.Specialization}</h3>
              <p>{education["University/College"]}</p>
              <p>GPA: {education.GPA}</p>
              <p className={styles.sectionDate}>{education["Start Date"]} - {education["End Date"]}</p>
            </div>
          ))}
          </div>
        </div>
      </div>
    </div>
  )
};

export default Template4;