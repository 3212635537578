import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { InputFieldCustomeStyle, InputSelectCustomeStyle } from './ProcessResumeUtils';
import InputAdornment from '@mui/material/InputAdornment';
import {ReactComponent as PersonalSiteIcon} from "../../assets/SVG/personal-site.svg";
import {ReactComponent as DatePickerIcon} from "../../assets/SVG/date-picker.svg";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import styled from "styled-components";
import EducationCard from "./EducationCard";
import { useDispatch, useSelector } from "react-redux";
import { setExtractedData } from "../../redux/actions";
import { useForm, Controller } from 'react-hook-form';
import { InputLabel } from "@mui/material";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import { Autocomplete, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { skillsData } from "../../Data/skillsData";
import { AiOutlineRobot } from 'react-icons/ai'; // Import an icon from react-icons
import ImproveResumeBtn from "../ImproveResumeBtn";

type FormDataType = {
  company: string;
  role: string;
  startDate: Date | null;
  endDate: Date | null;
  keyResponsibilities: string;
  projectDetails: string;
  toolsTechnologies: string;
};

const DEFAULT_FORM_DATA:FormDataType = {
  company: '',
  role: '',
  startDate: null,
  endDate: null,
  keyResponsibilities: '',
  projectDetails: '',
  toolsTechnologies: '',
};

// Define Quill modules with customized toolbar
export const quillModules = {
  toolbar: [
    [{ 'list': 'ordered' }, { 'list': 'bullet' }], // Lists
    ['bold', 'italic', 'underline'], // Text formatting
    ['clean'] // Clean formatting
  ],
};

const WorkExperience = () => {
  const dispatch = useDispatch();
  const extractedData = useSelector((state:any) => state.extractedData);
  const { handleSubmit, control} = useForm<FormDataType>();
    const [editingIndex, setEditingIndex] = useState(null as null | number);
    const [formData, setFormData] = useState(DEFAULT_FORM_DATA);
    const [errors,setErrors] = useState({
      role: "",
      company:"",
      startDate:"",
      endDate:""
    });
    const [jobType,setJobType] = useState("full_time");
    const [open, setOpen] = useState(false);
    const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
    const [responsibilities, setResponsibilities] = useState([]);

    const handlejobTypeChange = (event: SelectChangeEvent) => {
      setJobType(event.target.value as string);
    };

    const handleChange = (newValue: any, key: string) => {
      setFormData({
        ...formData,
        [key]: newValue,
      });
    };

    useEffect(() => {
      if (editingIndex !== null) {
        setFormData(extractedData.workExperiences[editingIndex]);
      }
      setErrors({
        role: "",
        company:"",
        startDate:"",
        endDate:""
      })
    }, [editingIndex]);

    useEffect(() => {
      dispatch(setExtractedData({
        ...extractedData,
        workExperiences: extractedData.workExperiences.map((item: any, index: number) => {
          if (index === editingIndex) {
            return formData;
          }
          return item;
        }),
      }));
    }, [formData]);

    const deleteWorkExperience = (index: number) => {
      dispatch(setExtractedData({
        ...extractedData,
        workExperiences: extractedData.workExperiences.filter((item: any, i: number) => i !== index),
      }));
    }

    const handleStartDateChange = (newValue: Date | null) => {
      if(newValue === null) return;
      const date = new Date(newValue);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      
      const formattedDate = `${year}-${month}-${day}`;

      handleChange(formattedDate, 'startDate');
      if (newValue && formData?.endDate !== null && newValue > (formData?.endDate as any)) {
        handleChange(null, 'endDate');
      }
    };
  
    const handleEndDateChange = (newValue: Date | null) => {
      if(newValue === null) return;
      const date = new Date(newValue);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      
      const formattedDate = `${year}-${month}-${day}`;
      handleChange(formattedDate, 'endDate');
    };

    const calculateYearsOfExperience = () => {
      if (formData?.startDate && formData?.endDate) {
        const startDate = new Date(formData?.startDate);
        const endDate = new Date(formData?.endDate);
        const years = endDate.getFullYear() - startDate.getFullYear();
        const months = endDate.getMonth() - startDate.getMonth();
        return years + months / 12;
      }
      return 0;
    };

    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleSkillSelect = (skill: string) => {
      setSelectedSkills((prev: string[]) => [...prev, skill]);
    };
  
    const handleResponsibilityClick = (responsibility: string) => {
      handleChange(formData.keyResponsibilities + responsibility + "\n", 'keyResponsibilities');
    };
  
    const handleGenerateResponsibilities = async () => {
      try {
        const yearsOfExperience = calculateYearsOfExperience();
        const response = await fetch('https://be.tekno.ai/generate-key-responsibilities', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            yearsOfExperience,
            skills: selectedSkills,
            role: formData.role,
            company: formData.company,
          }),
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const data = await response.json();
        setResponsibilities(data.responsibilities);
      } catch (error) {
        console.error('Error generating key responsibilities:', error);
      }
    };

    const handleAddExperience = () => {
      setEditingIndex(extractedData.workExperiences.length);
      setFormData(DEFAULT_FORM_DATA);
      // dispatch(setExtractedData({
      //   ...extractedData,
      //   workExperiences: [
      //     ...extractedData.workExperiences,
      //     DEFAULT_FORM_DATA
      //   ],
      // }));
    }

    const handleBack = () => {
      setEditingIndex(null);
    }

    const handleSave = () => {
      if(!formData?.role) {
        setErrors((prev)=>({
          ...prev,
          role:"Role is required"
        }))
      }
      if(!formData?.company) {
        setErrors((prev)=>({
          ...prev,
          company:"Company name is required"
        }))
      }
      if(!formData?.startDate){
        setErrors((prev)=>({
          ...prev,
          startDate:"Start Date is required"
        }))
      }
      if(!formData?.endDate) {
        setErrors((prev)=>({
          ...prev,
          endDate:"End Date is required"
        }))
      }
      if(formData?.role && formData?.company && formData?.startDate && formData?.endDate) {
        if(editingIndex === extractedData.workExperiences?.length) {
          /**
           * New Entry
          */
          dispatch(setExtractedData({
            ...extractedData,
            workExperiences: [
              ...extractedData.workExperiences,
               formData
            ],
          }));
        }
        else {
          dispatch(setExtractedData({
            ...extractedData,
            workExperiences: [
              ...extractedData.workExperiences,
            ],
          }));
        }
        setEditingIndex(null);
      }
    };

    return (
        <Box className="work-experience-container">
            <Box className="work-experience-wrapper">
              <Box className="work-experience-content">
                <Box className="main-heading">
                  <Box className="heading-box" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                      <Typography variant="h5" color="color.primary" className="heading">
                          Work Experience
                      </Typography>
                      <ImproveResumeBtn section={"workExperiences"} value={extractedData.workExperiences}/>
                  </Box>
                  <Box className="page-indicator-box">
                  </Box>
                </Box>


                {
                  editingIndex !== null ? (
                    <Box className="experience-wrapper">
                      <Box className="full-time" component="form" noValidate autoComplete="off">
                      <Box className="input-box-container">
                        <InputLabel htmlFor="role">
                            Job type
                        </InputLabel>
                        <Select
                          value={jobType}
                          onChange={handlejobTypeChange}
                          fullWidth
                        >
                          <MenuItem value={"full_time"}>Full time</MenuItem>
                          <MenuItem value={"part_time"}>Part time</MenuItem>
                        </Select>
                      </Box>
                    
                      <Box className="input-box-container">
                        <InputLabel htmlFor="company">Organization*</InputLabel>
                        <TextField
                          fullWidth
                          id="company"
                          name="company"
                          
                          placeholder="Ex: Microsoft Corporation"
                          value={formData?.company}
                          onChange={(e) => handleChange(e.target.value, 'company')}
                          error={!!errors.company}
                          helperText={errors.company ? errors.company : ''}
                        />
                      </Box>

                      <Box className="input-box-container">
                        <InputLabel htmlFor="role">
                            Job Title*
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="role"
                          name="role"
                          placeholder="Ex. Software Engineer"
                          value={formData?.role}
                          onChange={(e) => handleChange(e.target.value, 'role')}
                          
                          error={!!errors.role}
                          helperText={errors.role ? errors.role : ''}
                        />
                      </Box>

                      <Box className="input-box-container">
                        <InputLabel htmlFor="location">
                            Location
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="location"
                          name="location"
                          // value={formData?.role}
                          // onChange={(e) => handleChange(e.target.value, 'role')}
                          
                          error={!!errors.role}
                          helperText={errors.role ? errors.role : ''}
                        />
                      </Box>

                        <Box className="input-box-container">
                          <InputLabel htmlFor="startDate">
                                Start Date
                          </InputLabel>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            value={formData?.startDate as any}
                            onChange={handleStartDateChange}
                            slots={{
                                openPickerIcon: DatePickerIcon,
                                textField: (params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    id="startDate"
                                    name="startDate"
                                    value={formData?.startDate}
                                    
                                    error={!!errors.startDate}
                                    helperText={errors.startDate ? errors.startDate : ""}
                                  />
                                ),
                              }}
                              />
                        </LocalizationProvider>
                        </Box>

                        <Box className="input-box-container">
                            <InputLabel htmlFor="endDate">
                                    End Date
                            </InputLabel>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                              value={formData?.endDate as any}
                              onChange={handleEndDateChange}
                              minDate={formData?.startDate ? formData?.startDate as any : undefined}
                              slots={{
                                openPickerIcon: DatePickerIcon,
                                textField: (params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    id="endDate"
                                    name="endDate"
                                    value={formData?.endDate}
                                    
                                    error={!!errors?.endDate}
                                    helperText={errors?.endDate ? errors.endDate : ""}
                                  />
                                ),
                              }}
                              /> 
                          </LocalizationProvider>
                        </Box>
                    </Box>

                    <Box className="input-box-container"> 
                          <InputLabel htmlFor="address">
                          Description
                          </InputLabel>
                            <Box className="quill-container">
                              <ReactQuill 
                                value={formData?.keyResponsibilities}
                                onChange={(e: any) => handleChange(e, 'keyResponsibilities')}
                                theme="snow" 
                                modules={quillModules}
                              />
                            </Box>
                    </Box>

                      <Box className="input-box-container">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpen}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '10px 20px',
                            backgroundColor: '#1976d2',
                            '&:hover': {
                              backgroundColor: '#1565c0',
                            },
                            borderRadius: '8px',
                            boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
                          }}
                        >
                          <AiOutlineRobot style={{ marginRight: '8px' }} />
                          Generate Key Responsibilities with AI
                        </Button>

                        <Dialog open={open} onClose={handleClose}>
                          <DialogTitle>Generate Key Responsibilities</DialogTitle>
                          <DialogContent>
                            <Box sx={{ marginBottom: '16px' }}>
                              <TextField
                                label="Years of Experience"
                                value={calculateYearsOfExperience()}
                                onChange={(e) => handleChange(e.target.value, 'yearsOfExperience')}
                                fullWidth
                                margin="normal"
                              />
                            </Box>
                            <Box sx={{ marginBottom: '16px' }}>
                              <Typography variant="h6" gutterBottom>
                                Select Skills
                              </Typography>
                              <Autocomplete
                                multiple
                                options={skillsData}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, newValue) => {
                                  setSelectedSkills(newValue.map(skill => skill.value));
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Select Skills"
                                    placeholder="Start typing to search..."
                                  />
                                )}
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip
                                      label={option.label}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                sx={{ marginBottom: '16px' }}
                              />
                            </Box>
                            <Box sx={{ marginBottom: '16px' }}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleGenerateResponsibilities}
                                fullWidth
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  padding: '10px 20px',
                                  backgroundColor: '#1976d2',
                                  '&:hover': {
                                    backgroundColor: '#1565c0',
                                  },
                                  borderRadius: '8px',
                                  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
                                }}
                              >
                                Generate Responsibilities
                              </Button>
                            </Box>
                            <Box sx={{ marginBottom: '16px' }}>
                              <Typography variant="h6" gutterBottom>
                                Key Responsibilities
                              </Typography>
                              <Grid container spacing={1}>
                                {responsibilities.map((responsibility, index) => (
                                  <Grid item key={index}>
                                    <Chip label={responsibility} onClick={() => handleResponsibilityClick(responsibility)} />
                                  </Grid>
                                ))}
                              </Grid>
                            </Box>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleClose} color="primary">
                              Close
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Box>


                      
                      {/* <Box className="input-box-container">
                        <InputLabel htmlFor="address">
                          Project Details
                        </InputLabel>
                        <TextField
                          multiline
                          rows={2}
                          fullWidth
                          id="projectDetails"
                          name="projectDetails"
                          
                          placeholder="Ex: +91 123456789"
                          value={formData?.projectDetails}
                          onChange={(e) => handleChange(e.target.value, 'projectDetails')}
                          />
                      </Box> */}

                      <Box className="button-container">
                      <Button variant="outlined" size="medium" sx={{color:"black",border:"1px solid black"}} onClick={handleBack}>Cancel</Button>
                        <Button variant="contained" size="medium" sx={{background:"#5594ff"}} onClick={handleSave}>Save</Button>
                      </Box>
                    </Box>

                  ):
                  extractedData.workExperiences && extractedData.workExperiences.map((workExperience: any, index: number) => (
                    <EducationCard
                      details={{
                        heading: workExperience.role,
                        subHeading: workExperience.company,
                        startDate: workExperience.startDate,
                        endDate: workExperience.endDate,
                        city: workExperience.location,
                        aboutText: workExperience.keyResponsibilities,
                        company: workExperience?.company,
                      }}
                      handleEditClick={() => setEditingIndex(index)}
                      handleDeleteClick={() => deleteWorkExperience(index)}
                    />
                  ))
                }

                <Box className="add-work-exp">
                  <Typography variant="body2" className="color-primary" onClick={handleAddExperience}>Add Experience</Typography>
                </Box>
              </Box>
            </Box> 
        </Box>
    )
}
export default WorkExperience;