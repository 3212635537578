import Button from "@mui/material/Button";
import  Box  from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { InputFieldCustomeStyle } from "./ProcessResumeUtils";
import { ChangeEvent, useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import { hobbiesData, languagesData, nonTechSkillsData, skillOption, skillsData } from "../../Data/skillsData";
import Autocomplete,{createFilterOptions} from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from "react-redux";
import { setExtractedData } from "../../redux/actions";
// import { GithubDark, JavaScript, ReactDark } from "@fdorantesm/react-skill-icons";
import { Avatar } from "@mui/material";
import { StateProps } from "../../redux/reducer";

const filter = createFilterOptions<skillOption>();

const Skills = () => {
  const extractedData = useSelector((state:StateProps) => state.extractedData);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    console.log(extractedData,"from skills")
    const [skills, setSkills] = useState<skillOption[]>(extractedData?.toolsTechnicalSkills ?? []);
    const [availableSkills, setAvailableSkills] = useState(skillsData);

    const [nonTechSkills,setNonTechSkills] = useState<skillOption[]>(extractedData?.nonTechnicalSkills ?? []);
    const [availableNonTechSkills, setAvailableNonTechSkills] = useState(nonTechSkillsData);

    /**
     * Below use Effect is to get the value of already existing skills
    */
    useEffect(()=>{
      if(skills?.length > 0) {
        dispatch(setExtractedData(
          {
            ...extractedData,
            toolsTechnicalSkills:skills
          }
        ))
      }
      else {
        dispatch(setExtractedData(
          {
            ...extractedData,
            toolsTechnicalSkills:[]
          }
        ))
      }
    },[skills])

    /**
     * Below use Effect is to get the value of already existing nonTechnicalSkills
    */
        useEffect(()=>{
          if(nonTechSkills?.length > 0) {
            dispatch(setExtractedData(
              {
                ...extractedData,
                nonTechnicalSkills:nonTechSkills
              }
            ))
          }
          else {
            dispatch(setExtractedData(
              {
                ...extractedData,
                nonTechnicalSkills:[]
              }
            ))
          }
        },[nonTechSkills])

    const handleSkillChange = (event:any, newValue:any[]) => {
      setSkills(newValue);
      const updatedAvailableSkills = skillsData.filter(skill => !newValue.some(selectedSkill => selectedSkill.value === skill.value));
      setAvailableSkills(updatedAvailableSkills); // Update options after selection
    };

    const handleNonTechSkillChange = (event:any, newValue:any[]) => {
      setNonTechSkills(newValue);
      const updatedAvailableNonTexhSkills = nonTechSkillsData.filter(skill => !newValue.some(selectedSkill => selectedSkill.value === skill.value));
      setAvailableNonTechSkills(updatedAvailableNonTexhSkills); // Update options after selection
    };
  
    const handleSkillDelete = (skillToDelete: string) => {
      const updatedSkills = skills.filter(skill => skill.value !== skillToDelete);
      const deletedSkill = skillsData.find(skill => skill.value === skillToDelete);
      if (deletedSkill) {
        setAvailableSkills([...availableSkills, deletedSkill]);
      }
      setSkills(updatedSkills);
    };

    const handleNonTechSkillDelete = (skillToDelete: string) => {
      const updatedSkills = nonTechSkills.filter(skill => skill.value !== skillToDelete);
      const deletedSkill = nonTechSkillsData.find(skill => skill.value === skillToDelete);
      if (deletedSkill) {
        setAvailableNonTechSkills([...availableNonTechSkills, deletedSkill]);
      }
      setNonTechSkills(updatedSkills);
    };

    return (
        <Box className="skills-container">
            <Box className="skills-wrapper">
                <Box className="skills-content">
                <Box className="main-heading">
                        <Box className="heading-box">
                            <Typography variant="h5" color="color.primary" className="heading">
                                Technical Skills
                            </Typography>
                        </Box>
                </Box>

                <Box className="skills-section">
                    <Box className="input-box-container">
                          <Autocomplete
                            multiple
                            id="skills"
                            fullWidth
                            options={availableSkills} // Use updated available skills
                            //@ts-ignore
                            value={skills}
                            //value={skills.map(({label,value}) => ({label,value}))}
                            onChange={handleSkillChange}
                            renderTags={()=>null}
                            renderOption={(props, option) => (
                              <li {...props} style={{ display: 'flex', alignItems: 'center' }}>
                                 {option.icon && <option.icon style={{ marginRight: '8px', width:"26px", height:"26px" }} />}
                                {option.label}
                              </li>
                            )}
                            freeSolo
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Ex.Java"
                            />
                            )}                  
                            />

                    </Box>

                   {skills.length > 0 && <Box
                      sx={{
                        marginTop:1,
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1.4,
                        padding:0,
                        borderRadius: '8px',
                      }}
                    >
                      {skills?.map((skill, index) => (
                          <Box>
                            <Chip
                              sx={{
                              height:"2.2rem",
                              fontSize: '14px', 
                              fontWeight:400,
                              padding: '0.3rem', 
                              border: '1px solid #e2e8f0',
                              borderRadius: '8px', 
                              position: 'relative', 
                              '& .MuiChip-deleteIcon': {
                                position: 'absolute',
                                top: '-7px',
                                right: '-13px',
                                fontSize: '17px',
                                
                              },
                            }}  
                            avatar={skill.icon ? <skill.icon/> : <Avatar>M</Avatar>}
                              label={skill.label}
                              variant="outlined"
                              onDelete={()=> handleSkillDelete(skill.value)}
                            />
                          </Box>

                        ))
                      }
                    </Box>}
                </Box>

                <Box className="main-heading">
                        <Box className="heading-box">
                            <Typography variant="h5" color="color.primary" className="heading">
                                Non Technical Skills
                            </Typography>
                        </Box>
                </Box>

                <Box className="skills-section">
                    <Box className="input-box-container">
                          <Autocomplete
                            multiple
                            id="nonTeckSkills"
                            fullWidth
                            options={availableNonTechSkills} // Use updated available skills
                            //@ts-ignore
                            value={nonTechSkills}
                            onChange={handleNonTechSkillChange}
                            renderTags={()=>null}
                            renderOption={(props, option) => (
                              <li {...props} style={{ display: 'flex', alignItems: 'center' }}>
                                {option.label}
                              </li>
                            )}
                            freeSolo
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Ex.Communication"
                            />
                            )}                  
                            />

                    </Box>

                   {nonTechSkills.length > 0 && <Box
                      sx={{
                        marginTop:1,
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1.4,
                        padding:0,
                        borderRadius: '8px',
                      }}
                    >
                      {nonTechSkills?.map((skill, index) => (
                          <Box>
                            <Chip
                              sx={{
                              height:"2.2rem",
                              fontSize: '14px',
                              fontWeight:400,
                              padding: '0.3rem', 
                              border: '1px solid #e2e8f0',
                              borderRadius: '8px', 
                              position: 'relative', 
                              '& .MuiChip-deleteIcon': {
                                position: 'absolute',
                                top: '-7px',
                                right: '-13px',
                                fontSize: '17px', 
                              },
                            }}  
                              label={skill.label}
                              variant="outlined"
                              onDelete={()=> handleNonTechSkillDelete(skill.value)}
                            />
                          </Box>

                        ))
                      }
                    </Box>}
                </Box>

                {/* <Box
                      sx={{
                        marginTop:1,
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1.4,
                        padding:0,
                        borderRadius: '8px',
                      }}
                    >
                      {nonTechSkills?.map((sk, index) => (
                          <Box>
                            <Chip
                              sx={{
                              height:"2.2rem",
                              fontSize: '14px', // Adjust font size
                              fontWeight:400,
                              padding: '0.3rem', // Adjust padding
                              border: sk.isSelected ? "1px solid #5594ff" : '1px solid #e2e8f0',
                              background: sk.isSelected ? "#f7f9fd" : "#fff",
                              borderRadius: '8px', // Optional: Adjust border radius
                                '& .MuiChip-deleteIcon': {
                                  fontSize: '16px', // Adjust the size of the delete icon
                                },
                            }}
                            onClick={()=>handleNonTechSkillClick(sk.label)}  
                              label={sk.label}
                              variant="outlined"
                              // onDelete={()=> handleSkillDelete(skill.value)}
                            />
                          </Box>

                        ))
                      }
                </Box> */}

                {/* <Box className="skills-section">
                <Typography variant="h5" color="color.primary" className="heading">
                    Hobbies
                </Typography>
                    <Typography variant="h6" className="ui-text-3-color description">This will be shown on resume doc/pdf file you export</Typography>
                    <Box className="input-box-container">
                             <Autocomplete
                            multiple
                            id="hobbies"
                            fullWidth
                            options={hobbiesData}
                            value={hobbies}
                            onChange={handleHobbyChange}
                            getOptionLabel={(option:any) => {
                                // Value selected with enter, right from the input
                                if (typeof option === "string") {
                                  return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                  return `Add ${option.inputValue}`;
                                }
                                // Regular option
                                return option.label;
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                        
                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some(
                                  (option) => inputValue === option.label
                                );
                                if (inputValue !== "" && !isExisting) {
                                  filtered.push({
                                    inputValue,
                                    label: `Add ${inputValue}"`,
                                    value: `Add ${inputValue}"`,
                                  });
                                }
                                return filtered;
                              }}
                            freeSolo
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Ex.Football"
                                sx={InputFieldCustomeStyle}
                            />
                            )}
                            />
                    </Box>
                </Box>

                <Box className="skills-section">
                <Typography variant="h5" color="color.primary" className="heading">Languages</Typography>
                    <Typography variant="h6" className="ui-text-3-color description">This will be shown on resume doc/pdf file you export</Typography>
                    <Box className="input-box-container">
                             <Autocomplete
                            multiple
                            id="hobbies"
                            fullWidth
                            options={languagesData}
                            value={languages}
                            onChange={handleLanguageChange}
                            getOptionLabel={(option:any) => {
                                // Value selected with enter, right from the input
                                if (typeof option === "string") {
                                  return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                  return `Add ${option.inputValue}`;
                                }
                                // Regular option
                                return option.label;
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                        
                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some(
                                  (option) => inputValue === option.label
                                );
                                if (inputValue !== "" && !isExisting) {
                                  filtered.push({
                                    inputValue,
                                    label: `Add ${inputValue}"`,
                                    value: `Add ${inputValue}"`,
                                  });
                                }
                                return filtered;
                              }}
                            freeSolo
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Ex.English"
                                sx={InputFieldCustomeStyle}
                            />
                            )}
                            />
                    </Box>
                </Box> */}
                </Box>
            </Box>

            
        </Box>
    )
}

export default Skills;