import React from 'react';
import { LuPhone } from "react-icons/lu";
import { MdOutlineMail } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { isValueExist } from '../components/utils';
import ListTypeDetails from './sections/ListTypeDetails';
import ListTypeSkills from './sections/ListTypesSkills';
import { useSelector } from 'react-redux';
import { getValidKeyValuePairs } from '../components/common/CommonFunctions';
import SocialLinks from '../components/common/SocialLinks';




const Template7:React.FC = React.forwardRef((props:any,ref:any) => {
  const resumeDetails = useSelector((state:any) => state.extractedData);
  const tempColor = useSelector((state:any) => state.color);
  const socialLinks = getValidKeyValuePairs(resumeDetails?.socialLinks);

   return (
    <div className={`template7-container pick-${tempColor}`} ref={ref}>
      <div className={'header-section'}>

      <div className={"header-left-section"}>
        <div className={"header-left-section__svg"}>
       </div>
       </div>
      
      <div className={'header-right-section'}>
      <div className={"header"}>
        <h1>{resumeDetails?.name?.firstName}{resumeDetails?.name?.lastName}</h1>
        {isValueExist(resumeDetails?.designation) && <h2>{resumeDetails?.designation}</h2>}
      </div>
      <div className={"contact-info"}>
        {resumeDetails?.email && <p><span><MdOutlineMail/></span> {resumeDetails.email}</p>}
        {resumeDetails?.phone && <> <div className={'contact-info__divider'}></div>
        <p><LuPhone/> {resumeDetails.phone?.join(", ")}</p></>}
        {resumeDetails?.["location"] && <><div className={'contact-info__divider'}></div>
          <p><IoLocationOutline/> {resumeDetails?.["location"]}</p></>} 
      </div>
      {socialLinks?.[0] && <SocialLinks links={socialLinks} iconColor={"grey"} horizontal={true} iconSize={"0.85rem"} dot={true}/>} 
      </div> 
      
      </div>
       

      <div className={"main-content"}>
      {isValueExist(resumeDetails?.["careerObjective"]) && 
      <>
       <div className={"upper-border"}><div className={"heading-border"}></div></div>
      <div className={"experience-section"}>
      <div className={"heading-container"}><h2>Objective</h2><div className={"horizontal-line"}><div className={"line"}></div></div></div> 
      <div>
              {resumeDetails["careerObjective"]}
            </div>
      </div>
      </>
          }



        <div className={"upper-border"}><div className={"heading-border"}></div></div>
        <div className={"skills-section"}>
         <div className={"heading-container"}><h2>Skills</h2><div className={"horizontal-line"}><div className={"line"}></div></div></div>  
          {typeof resumeDetails["toolsTechnicalSkills"] === "string" ? <ListTypeSkills skillStyle={"skill"} skills={resumeDetails["toolsTechnicalSkills"]?.split(",")}/> :<ListTypeSkills skillStyle={"skill"} skills={resumeDetails["toolsTechnicalSkills"]}/>}
        </div>

        <div className={"upper-border"}><div className={"heading-border"}></div></div>
        <div className={"experience-section"}>
        <div className={"heading-container"}><h2>Experience</h2><div className={"horizontal-line"}><div className={"line"}></div></div></div> 
          <ul className={"experience-container"}>
          {resumeDetails?.['workExperiences'] && resumeDetails?.['workExperiences'].map((experience: any, index: number) => (
            <li className={"experience"} key={index}>
              <h3>{experience.role} at {experience.company}</h3>
              <p>{experience?.["startDate"]} - {experience?.["endDate"]}</p>
              
                {isValueExist(resumeDetails?.['workExperiences']?.[index]?.["keyResponsibilities"]) && 
                (typeof experience["keyResponsibilities"] === "string" 
                ? <ListTypeDetails details={experience["keyResponsibilities"]?.split(",")}/>
                 : <ListTypeDetails details={experience["keyResponsibilities"]} />)}
               
                    {isValueExist(resumeDetails?.['Work Experiences']?.[index]?.["Project Details"]) &&
                     (typeof experience["projectDetails"] === "string"
                      ? <ListTypeDetails details={experience["PprojectDetails"]?.split(",")}/> 
                      : <ListTypeDetails details={experience["projectDetails"]} />)}
                  
            </li>
          ))}
          </ul>
        </div>

        <div className={"upper-border"}><div className={"heading-border"}></div></div>
        <div className={"education-section"}>
        <div className={"heading-container"}><h2>Education</h2><div className={"horizontal-line"}><div className={"line"}></div></div></div> 
          <ul className={"skill-container"}>
          {resumeDetails?.['educationalDetails'] && resumeDetails['educationalDetails'].map((education: any, index: number) => (
            <li className="education" key={index}>
              <h3>{education.degree} in {education.specialization}</h3>
              <p>{education["universityCollege"]}</p>
              <p>GPA {education?.gpa}</p>
              <p>{education?.["startDate"]} - {education?.["endDate"]}</p>
            </li>
          ))}
          </ul>
        </div>
      </div>
    </div>
   )
});

export default Template7;