import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { useSelector } from 'react-redux';
import { StateProps } from '../redux/reducer';
import {ReactComponent as DashboardIcon} from "../assets/SVG/dsahbordSidebar.svg";
import {ReactComponent as ResumesIcon} from "../assets/SVG/resumeSidebar.svg";
import {ReactComponent as AtsIcon} from "../assets/SVG/atsCheckerSidebar.svg";
import {ReactComponent as ProfileIcon} from "../assets/SVG/myProfileSidebar.svg";
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;

const sidebarmenuList = [
    {
        name:"Dashboard",
        icon:<DashboardIcon/>,
        url:"/",
    },
    {
        name:"Resumes",
        icon:<ResumesIcon/>,
        url:"/my-resumes",
    },
    {
        name:"ATS Checker",
        icon:<AtsIcon/>,
        url:"/ats-check-result",
    },
    {
        name:"My Profile",
        icon:<ProfileIcon/>,
        url:"/",
    },
]

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });
  
  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),

    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      position:'static',
      variants: [
        {
          props: ({ open }) => open,
          style: {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
          },
        },
        {
          props: ({ open }) => !open,
          style: {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
          },
        },
      ],
    }),
  );

  interface SideDarwerProps {
    open: boolean;
  }

const SideDrawer = () => {
const theme = useTheme();
const navigate = useNavigate();
const open = useSelector((state:StateProps)=> state.sideDrawer)

// const handleDrawerOpen = () => {
//     setOpen(true);
//   };

//   const handleDrawerClose = () => {
//     setOpen(prev=>!prev);
//   };

  return (
    <Drawer variant="permanent" open={open}>
    <DrawerHeader sx={{minHeight:"3rem !important"}}>
      <IconButton>
        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>
    </DrawerHeader>
    <Divider />
    <List>
      {sidebarmenuList.map((element, index) => (
        <ListItem onClick={() => navigate(element.url)} key={element.name} disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={[
              {
                minHeight: 48,
                px: 2.5,
              },
              open
                ? {
                    justifyContent: 'initial',
                  }
                : {
                    justifyContent: 'center',
                  },
            ]}
          >
            <ListItemIcon
              sx={[
                {
                  minWidth: 0,
                  justifyContent: 'center',
                },
                open
                  ? {
                      mr: 3,
                    }
                  : {
                      mr: 'auto',
                    },
              ]}
            >
              {element.icon}
            </ListItemIcon>
            <ListItemText
              primary={element.name}
              sx={[
                open
                  ? {
                      opacity: 1,
                      color:"#98a2b3",
                    }
                  : {
                      opacity: 0,
                      color:"#98a2b3",
                    },
              ]}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
   
  </Drawer>
  )
}

export default SideDrawer ;