import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import {ReactComponent as CreateResumeIcon } from "./assets/SVG/user-landing-page-create-resume.svg";
import {ReactComponent as UploadResumeIcon} from "./assets/SVG/user-landing-page-upload-resume.svg";
import {ReactComponent as ATSChckerIcon} from "./assets/SVG/user-landing-page-ats-checker.svg";
import {ReactComponent as OnePageResumeIcon } from "./assets/SVG/user-landing-page-convert-to-single-page.svg";
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setExtractedData } from './redux/actions';
import Loading from './components/Loading/Loading';
import { Alert, Snackbar } from '@mui/material';
import Header from './components/common/Header';

import AtsResumeCheckerPopup from './components/ATSChecker/AtsResumeCheckerPopup';
import { extractFaceFromPDF, WorkExperienceTypes } from './components/utils';
import SideDrawer from './ProcessResume/SideDrawer';
import AppBarHeader from './ProcessResume/AppBarHeader';
// import { getDocument } from 'pdfjs-dist';
// const PDFJS = window.pdfjsLib;

interface userOption {
    title:string;
    subTitle:string;
    Icon:React.ReactNode;
    svgClass:string;
    link:string;
    onClick?: () => void;
}

const UserLandingPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    
    const [isLoading, setIsLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [pdfBase64, setPdfBase64] = useState('');
    const [images, setImages] = useState([]);

    const [resumeActionType, setResumeActionType] = useState('');

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleOpenPopup = () => {
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    // const convertToImage = async(file:File) => {
    //     if(!file) return;
    //     try {
    //         // Load the PDF document
    //           debugger;
    //           const uri = URL.createObjectURL(file);
    //           const pdfDoc = await pdfjsLib.getDocument({ data: uri }).promise;
    //           console.log(pdfDoc,"from middle")
              
              
    //         // Get the first page
    //           const page = await pdfDoc.getPage(1);

    //           // Set up the canvas for rendering
    //           const viewport = page.getViewport({ scale: 1 });
    //           const canvas = document.createElement('canvas');
    //           const context = canvas.getContext('2d');

    //           if (!context) {
    //               console.error('Failed to get canvas context');
    //               return;
    //             }
            

    //           canvas.width = viewport.width;
    //           canvas.height = viewport.height;

    //           // Render the page into the canvas
    //           await page.render({
    //           canvasContext: context,
    //           viewport: viewport,
    //           }).promise;

         

    //           // Convert the canvas to an image
    //           const imgDataUrl = canvas.toDataURL('image/png');
    //           return imgDataUrl; 

    //         const uri = URL.createObjectURL(file);
    //         var _PDF_DOC = await pdfjsLib.getDocument({ url: uri });
    //         const imagesList = [];
    // const canvas = document.createElement("canvas");
    // canvas.setAttribute("className", "canv");
    // let canv = document.querySelector(".canv");

    // for (let i = 1; i <= pdf.numPages; i++) {
    //   var page = await _PDF_DOC.getPage(1);
    //   var viewport = page.getViewport({ scale: 1 });
    //   canvas.height = viewport.height;
    //   canvas.width = viewport.width;
    //   var render_context = {
    //     canvasContext: canvas.getContext("2d"),
    //     viewport: viewport
    //   };
    //   console.log("page lenght", pdf.numPages);
    //   setWidth(viewport.width);
    //   setHeight(viewport.height);
    //   await page.render(render_context).promise;
    //   let img = canvas.toDataURL("image/png");
    //   imagesList.push(img);
    // }
    //     }
    //     catch{
    //         throw new Error('Failed to convert pdf into image');
    //     }

    // }

    const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        if (event.target.files?.length) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file);
            console.log(process.env.REACT_APP_API_URL,"from API")

            try {
                const response = await fetch(`https://testapi.tekno.ai/api/extract-text`, {
                    method: 'POST',
                    body: formData,
                });
                if (response.ok) {
                    const {textContent} = await response.json();
                    const requestData = { content: textContent } as any;
                    const endpointURL = resumeActionType === 'single-page-conversion' ? `https://be.tekno.ai/convert-resume-to-single-page` : `${process.env.REACT_APP_API_URL}/generate-content`;
                    const modelResponse = await fetch(endpointURL, {
                        method: 'POST',
                        body: JSON.stringify(requestData),
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    const resumeObj = await modelResponse.json();

                    /**
                     * Converting pdf to image
                    */

                    const reader:any = new FileReader();
                    reader.onloadend = () => {
                        const base64string = reader.result.split(',')[1];
                        const extractedFace = extractFaceFromPDF(base64string);
                        if (extractedFace) {
                            resumeObj.profile_Picture = extractedFace;
                        }
                    };
                    reader.readAsDataURL(file);
                    console.log("resumeObj", resumeObj);
                    // const imgDataUrl = convertToImage(file);

                    //formatting resume object for projects section
                    let formattedResumeObject = resumeObj;
                    if(resumeObj?.projects?.[0]) {
                        const formattedProjects = resumeObj?.projects?.map((proj:any)=>({...proj,organization:proj?.title,courseDescription:proj?.description}));
                        formattedResumeObject = {...formattedResumeObject, projects: formattedProjects}
                    }
                     if(resumeObj?.workExperiences?.[0]) {
                        const formattedExperience:WorkExperienceTypes = resumeObj?.workExperiences?.map((exp:WorkExperienceTypes) => ({...exp,jobType:exp?.jobType ?? "fullTime",location:exp?.location ?? ""}));
                        formattedResumeObject = {...formattedResumeObject, workExperiences: formattedExperience}
                    }
                    if(resumeObj?.toolsTechnicalSkills && typeof resumeObj.toolsTechnicalSkills === "string") {
                        const formattedToolsTechnicalSkills:{label:string,value:string}[] = resumeObj?.toolsTechnicalSkills?.split(/[,;]+/)?.map((sk:string) => ({label:sk?.trim(),value:sk?.trim()}));
                        formattedResumeObject = {...formattedResumeObject, toolsTechnicalSkills: formattedToolsTechnicalSkills}
                    }
                    if(resumeObj?.nonTechnicalSkills && typeof resumeObj.nonTechnicalSkills === "string") {
                        const formattedNonTechnicalSkills:{label:string,value:string}[] = resumeObj?.nonTechnicalSkills?.split(/[,;]+/)?.map((sk:string) => ({label:sk?.trim(),value:sk?.trim()}));
                        formattedResumeObject = {...formattedResumeObject, nonTechnicalSkills: formattedNonTechnicalSkills}
                    }
                     
                    dispatch(setExtractedData(formattedResumeObject));
                    navigate('/process-resume/personal-details');
                } else {
                    console.error('Upload failed');
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const triggerFileInputClick = () => {
        fileInputRef.current?.click();
    };

    const userOptions: userOption[] = [
        {
            title:"Create New Resume",
            subTitle:"Create and customize your unique version yourself!",
            Icon:<CreateResumeIcon/>,
            svgClass:"svg-fill-secondary-hover-primary cursor-pointer",
            link:"/process-resume/personal-details",
        },
        // {
        //     title:"Choose Template",
        //     subTitle:"select from 100+ professional resumes",
        //     Icon:<ChooseTemplateIcon/>,
        //     svgClass:"svg-fill-stroke-secondary-hover-primary cursor-pointer",
        //     link:"/select-template",
        // },
        {
            title:"Upload Resume",
            subTitle:"Quickly create your resume with an easy upload!",
            Icon:<UploadResumeIcon/>,
            svgClass:"svg-stroke-secondary-hover-primary cursor-pointer",
            link:"/",
            onClick: triggerFileInputClick
        },
        {
            title:"ATS Resume Checker",
            subTitle:"Choose your existing Resume and Improve it’s ATS Score.",
            Icon:<ATSChckerIcon/>,
            svgClass:"svg-fill-stroke-secondary-hover-primary cursor-pointer",
            link:"/",
            onClick: handleOpenPopup
        },
        {
            title:"Convert to Single Page Resume",
            subTitle:"Simplify your multi-page resume into a single page!",
            Icon:<OnePageResumeIcon/>,
            svgClass:"svg-stroke-secondary-hover-primary cursor-pointer",
            link:"/",
            onClick: () => {
                setResumeActionType('single-page-conversion');
                triggerFileInputClick();
            }
        },
    ];

    const sendBase64Image = async (base64Image: string) => {
        // Convert base64 string to Blob
        const base64ToBlob = (base64: string, contentType: string) => {
            const byteCharacters = atob(base64);
            const byteArrays = [];
    
            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);
                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
    
            return new Blob(byteArrays, { type: contentType });
        };
    
        const contentType = 'image/png'; // Change this to the appropriate MIME type
        const blob = base64ToBlob(base64Image, contentType);
    
        // Create FormData and append the Blob
        const formData = new FormData();
        formData.append('file', blob, 'image.png'); // 'image.png' is the filename
    
        // Send the request
        try {
            const response = await fetch('https://testapi.tekno.ai/api/detect-profile', {
                method: 'POST',
                body: formData,
                headers: {
                    'Accept': '*/*',
                },
            });
    
            if (response.ok) {
                const result = await response.json();
                console.log('Success:', result);
            } else {
                console.error('Error:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    useEffect(() => {
        if (location.state?.isLoginSuccessful) {
            setOpenSnackbar(true);
        }
    }, [location]);

    return (
        isLoading ?
        <Loading/> :
        <>
        <Box className="app-bar-container">
            <AppBarHeader/>
        </Box>
        <Box className="side-drawer-container">
            <SideDrawer />
        </Box>
            <Box
            className="flex-column-center user-landing-page-container"
            >
                <Snackbar open={openSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={6000} onClose={handleCloseSnackbar} sx={{ mt: 6 }}>
                    <Alert
                        onClose={handleCloseSnackbar}
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        Welcome back! You've successfully logged in.
                    </Alert>
                </Snackbar>
                <Box>
                <Box className="top-heading-container">
                <Box sx={{mb: 4, textAlign: "center"}}>
                    <Typography variant="h4" sx={{fontWeight: "bold"}} className="heading">Let’s get Started !</Typography>                
                    <Typography variant="h6" color="text.secondary" className="sub-heding">We can begin in a few ways: pick the one that works best for you.</Typography>
                </Box>
                </Box>
                <Box className="landing-page-content">
                    <Grid container spacing={4} className="card-container">
                    {userOptions.map((option:userOption) => (
                        <Grid item md={3} onClick={() => option.onClick ? option.onClick() : navigate(`${option.link}`)}>
                            <Card
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                height: "350px",
                                borderRadius: "12px",
                                border: "2px solid var(--ui-border-1, #E2E8F0)",
                                background: "linear-gradient(45deg, #FFF 0%, #EAF6FF 100%)"
                                // border:"1.5px solid #fff",
                                // '&:hover': {
                                // border:"1.5px solid #5594ff",
                                    }}
                                    // className={option.svgClass}
                            >
                            <CardMedia>
                            <Box
                            pt={4}
                            className="flex-row-center"
                            >
                            {option.Icon} 
                            </Box>
                            </CardMedia>
                            <CardContent sx={{display:"flex",
                                flexDirection:"column",
                                justifyContent:"center",
                                alignItems:"center"}}>
                                <Typography variant="h6" component="div" sx={{fontWeight: "bold"}}>
                                {option.title}
                                </Typography>
                                <Typography variant="body1" color="text.secondary">
                                {option.subTitle}
                                </Typography>
                            </CardContent>
                            </Card>
                        </Grid>
                    ))}
                    </Grid>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileSelect}
                    />
                </Box>
                <Box sx={{mt: 4}} className="flex-row-center bottom-text-container" >
                    <Typography variant="h6" color="text.secondary">Need Any Help?<span className="color-primary"> email us.</span></Typography>
                </Box>
                </Box> 
            </Box>
            <AtsResumeCheckerPopup
                open={isPopupOpen}
                onClose={handleClosePopup}
            />
        </>
    )
}

export default UserLandingPage;